/* eslint-disable no-constant-condition */
import {
  Container,
  Image,
  Text,
  Box,
  HStack,
  Tooltip,
  Divider,
  Spinner,
  FormLabel,
  Flex,
  useClipboard,
  VStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import {
  AccountProviderEnum,
  CheckRuleUsedResponse,
  CurrencyCodeEnum,
  DefaultRule,
  GetOtherUserLabelsResp,
  IsDirtyEnum,
  LedgerTransactionReviewStatusEnum,
  LedgerTransactionStatusEnum,
  MutationHideTransactionArgs,
  MutationRuleToDefaultRuleArgs,
  MutationSendSlackNotificationArgs,
  MutationUpdateTransactionArgs,
  MutationUpdateTransferArgs,
  NeedsReview,
  NeedsReviewError,
  Query,
  QueryCheckRuleUsedArgs,
  QueryGetOtherUserLabelsArgs,
  QueryGetTransactionPageArgs,
  RuleTypeEnum,
  TransactionPageResponse,
  TransactionTypeOption,
} from "src/api/generated/types";
import { Input } from "src/components/styled/Form/Input";
import {
  BaseAccountFields,
  BaseClientFields,
  BaseFullTransactionFields,
} from "src/api/fragments";
import { colors, other } from "src/theme";
import { useTransactionById } from "src/hooks/useTransactionById";
import { hasValue, Maybe } from "src/core";
import {
  ActionSheet,
  Button,
  Copy,
  Info,
  RecalculateButton,
  Textarea,
} from "src/components/styled";
import { useMyToast } from "src/hooks";
import { DateTime } from "luxon";
import { ActiveTransactionContext } from "src/context";
import { compose } from "lodash/fp";
import Helpers, { D, timeAgo } from "src/utils/helpers";
import _, { isEmpty, isNil, noop, truncate } from "lodash";
import StatusTag, { StatusTagType } from "src/components/styled/StatusTag";
import { LabelSelect } from "src/components/Labels/LabelSelect";
import { FormValues, getDefaultValues, schema } from "./form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormProvider,
  SubmitErrorHandler,
  UseFormHandleSubmit,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import { Transfers } from "./Transfers/TransferForm";
import { Fees } from "./Fees";
import { useCreatedAtUTC } from "./utils";
import { TransferOverview } from "./Transfers";
import { Touchable } from "src/components/Touchable";
import { api, BaseLedgerEntryFullFields } from "src/api";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  getLink,
  formatAutoReviewReason,
  formatRuleUsed,
  isLabeled,
} from "src/modules/ledger/transactions";
import { getIsGlobalRuleMode } from "src/redux/reducers/globalState";
import { ConsoleView, isMobile } from "react-device-detect";
import { EntriesPopover } from "./components/EntriesPopover";
import { GraphQLError } from "graphql";
import { INTEGRATIONS, PROVIDER_TO_LOGO_URL } from "../AccountModal/constants";
import { useTransfers } from "./Transfers/TransferForm/utils";
import BigNumber from "bignumber.js";
import SecondaryText from "src/components/styled/SecondaryText";
import { Currency } from "dinero.js";
import moment from "moment-timezone";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { userInfo } from "os";
import { UnhideTransfers } from "./components";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  transactionId: string;
  showAssetTransfersBuilder: boolean;
  onSuccess: (acct?: BaseAccountFields) => void;
};

export const GET_TXN_STATUS: Record<
  LedgerTransactionStatusEnum,
  StatusTagType
> = {
  [LedgerTransactionStatusEnum.Canceled]: "error",
  [LedgerTransactionStatusEnum.Failed]: "error",
  [LedgerTransactionStatusEnum.Pending]: "info",
  [LedgerTransactionStatusEnum.Completed]: "success",
  [LedgerTransactionStatusEnum.Unknown]: "none",
};

function _TxnDetailModal({
  transactionId,
  handleHide,
  show,
  showAssetTransfersBuilder = false,
}: Props) {
  const { transaction } = useTransactionById(
    transactionId || null,
    "cache-first"
  );
  const transfers = transaction?.transfers || [];
  const transfersBreakdown = useTransfers(transfers);

  const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
    useMutation(api.transactions.update);

  const { data, refetch } = useQuery<{
    getTransactionLedgerEntries: BaseLedgerEntryFullFields[];
  }>(api.transactions.ledgerEntries, {
    variables: { transactionId },
    skip: !transactionId,
  });

  useEffect(() => void refetch(), [transactionId]);

  const entries = data?.getTransactionLedgerEntries || [];

  const defaultValues = useMemo(
    () => getDefaultValues(transaction),
    [transaction]
  );

  const formProps = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // Note: this is what we need to do to reset the async values we get after the txn is loaded from the server
  // otherwise, the defaults don't get set
  useEffect(() => {
    const currentValues = formProps.getValues();

    // maintain the notes in between the reset
    formProps.reset({
      ...defaultValues,
      notes: currentValues.notes || defaultValues.notes,
      labelUsed: currentValues.labelUsed || defaultValues.labelUsed,
    });
  }, [defaultValues]);

  return (
    <ActiveTransactionContext.Provider
      value={{
        transaction,
        transfers: transfersBreakdown,
        client: transaction?.client || null,
        showAssetTransfersBuilder,
        ledgerEntries: entries ?? [],
      }}
    >
      <FormProvider {...formProps}>
        <TransactionModal
          isVisible={show}
          handleHide={handleHide}
          transactionId={transactionId}
          updateTransaction={updateTransaction}
          isLoadingUpdateTransaction={isLoadingUpdateTransaction}
        />
      </FormProvider>
    </ActiveTransactionContext.Provider>
  );
}

// Note: we put the txn modal code in here so we can access form state
const TransactionModal = ({
  transactionId,
  handleHide,
  isVisible,
  updateTransaction,
  isLoadingUpdateTransaction,
}: {
  transactionId: string;
  handleHide: () => void;
  isVisible: boolean;
  updateTransaction: any;
  isLoadingUpdateTransaction: boolean;
}) => {
  const { transaction } = useContext(ActiveTransactionContext);
  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");
  const { background, header, medBackground, secondaryBackground, border } =
    useTheme();

  const {
    formState: { isDirty, dirtyFields },
  } = useFormContext<FormValues>();

  const _onHide = () => {
    if (!isEmpty(dirtyFields) || isDirty) {
      const isConfirmed = confirm(
        `Please save your transaction before you close this modal. If you do not save, your changes will be lost.`
      );
      if (isConfirmed) {
        search.delete("transactionId");
        search.set("highlightedTransactionId", transaction?.id || "");
        setSearchParams(search);
      }
    } else {
      search.delete("transactionId");
      search.set("highlightedTransactionId", transaction?.id || "");
      setSearchParams(search);
    }
  };

  useEffect(() => {
    if (!searchTransactionId) {
      handleHide();
    }
  }, [searchTransactionId]);

  const isLoading = isLoadingUpdateTransaction || !transaction;
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      minW={isLarge ? "50rem" : "inherit"}
      minH="40rem"
      title={isLoading ? <div /> : <Header handleHide={handleHide} />}
      isVisible={isVisible}
      handleHide={_onHide}
      w={isLarge ? "100%" : "95%"}
      footerProps={{
        visibility: isLoading ? "hidden" : "visible",
        padding: isLarge ? "1rem 2rem" : "1rem",
        borderTop: "1px solid " + border,
        background: medBackground,
        boxShadow: `0px 5px 5px 5px ${border}`, // <- needs to be outset, not inset
      }}
      trapFocus={false}
      titleHeaderProps={{
        marginTop: 0,
      }}
      headerProps={{
        padding: "0",
        marginTop: "0",
        borderBottom: "1px solid " + border,
      }}
      bodyProps={{
        padding: 0,
        maxH: isLoading ? "none" : "55vh",
        height: "100%",
        overflowY: "scroll",
      }}
      Footer={
        isLoading ? (
          <div />
        ) : (
          <TransactionModalFooter
            isLoading={isLoading}
            handleHide={handleHide}
            updateTransaction={updateTransaction}
            isLoadingUpdateTransaction={isLoadingUpdateTransaction}
          />
        )
      }
    >
      {isLoading ? (
        <Box
          w="100%"
          h="55vh"
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Spinner color={header} />
        </Box>
      ) : (
        <TransactionDetails />
      )}
    </Modal>
  );
};

type LabelOption = TransactionTypeOption & {
  isDisabled: boolean;
};

const SaveChangesButton = ({
  isLarge,
  isLoading,
  _updateTransaction,
  _onInvalid,
  handleSubmit,
  transaction,
}: {
  isLarge: boolean;
  isLoading: boolean;
  _onInvalid: SubmitErrorHandler<FormValues>;
  _updateTransaction: (values: FormValues) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  transaction: Maybe<BaseFullTransactionFields>;
}) => {
  const { setValue, watch } = useFormContext<FormValues>();
  const labelUsed = watch("labelUsed");
  // console.log(
  //   "Label used",
  //   labelUsed,
  //   "undefined: ",
  //   labelUsed === undefined,
  //   "null: ",
  //   labelUsed === null,
  //   "empty string: ",
  //   labelUsed === ""
  // );
  const needsRecalculate = transaction?.needsReview?.needsRecalculate === true;
  const hasLabelSet = isLabeled(transaction)
    ? !!transaction?.labelUsed && !labelUsed // reset label
      ? false
      : true
    : !!labelUsed;
  const isMissingLabel = !hasLabelSet;
  const hasEntriesError =
    !transaction ||
    (transaction.needsReview !== null &&
      transaction.needsReview?.errors.some((err) => err?.startsWith("Entry")));
  const isReviewed =
    transaction &&
    hasLabelSet &&
    !transaction.isMissingBasis &&
    !hasEntriesError;

  return (
    <Popover
      trigger={needsRecalculate ? undefined : "hover"}
      placement="bottom-end"
    >
      <PopoverTrigger>
        <Box>
          <Button
            display="block"
            size="sm"
            variant="primary"
            bgColor={isReviewed ? colors.primaryGreen : undefined}
            _hover={{
              bgColor: isReviewed ? colors.primaryGreen : undefined,
            }}
            style={{ padding: "0.75rem 1.5rem" }}
            disabled={isLoading}
            onClick={handleSubmit(_updateTransaction, _onInvalid)}
          >
            Save Changes{" "}
            <i
              style={{ marginLeft: "0.25rem" }}
              className="fa-sharp fa-arrow-right"
            />
          </Button>
        </Box>
      </PopoverTrigger>
      <PopoverContent width="15rem">
        <PopoverArrow />
        <Box w="100%" margin="0.5rem 0">
          <SecondaryText
            text={isReviewed ? "REVIEWED" : "UNREVIEWED"}
            fontWeight="bold"
            // paddingLeft="0.75rem"
            w="100%"
            textAlign="center"
          />
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  isMissingLabel ? "far fa-circle" : "fa-sharp fa-check-circle"
                }
                style={{
                  color: isMissingLabel ? undefined : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>&nbsp;&nbsp;&nbsp;Label</Text>
          </Box>
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  transaction?.isMissingBasis
                    ? "far fa-circle"
                    : "fa-sharp fa-check-circle"
                }
                style={{
                  color: transaction?.isMissingBasis
                    ? undefined
                    : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>
              &nbsp;&nbsp;&nbsp;Cost basis{" "}
              <Info message="To fix this warning, click into each of the red edit pens and either edit the fiat amount or save the value Awaken has set." />
            </Text>
          </Box>
          <Box w="100%" display="flex" padding="0.5rem 0.75rem">
            <Text>
              <i
                className={
                  hasEntriesError ? "far fa-circle" : "fa-sharp fa-check-circle"
                }
                style={{
                  color: hasEntriesError ? undefined : colors.primaryGreen,
                }}
              />
            </Text>
            <Text>&nbsp;&nbsp;&nbsp;Previous transactions</Text>
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

const TransactionModalFooter = ({
  isLoading,
  handleHide,
  updateTransaction,
  isLoadingUpdateTransaction,
}: {
  isLoading: boolean;
  handleHide: () => void;
  updateTransaction: any;
  isLoadingUpdateTransaction: boolean;
}) => {
  const { clientId } = useParams();
  const { transaction } = useContext(ActiveTransactionContext);
  const { handleSubmit } = useFormContext<FormValues>();
  const toast = useMyToast();
  const usingRule =
    transaction && !transaction.labelUsed && transaction.ruleUsed;
  const onlyHasRuleOrAutoReview =
    !transaction?.labelUsed &&
    !!(transaction?.ruleUsed || transaction?.autoReviewReason);
  const onlyHasAutoReview =
    !transaction?.labelUsed &&
    !transaction?.ruleUsed &&
    transaction?.autoReviewReason;
  const canEdit = onlyHasRuleOrAutoReview;
  const [showDropdown, setShowDropdown] = useState<boolean>(
    !onlyHasRuleOrAutoReview
  );
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  const [search, setSearchParams] = useSearchParams();

  // checkRuleUsed - don't use the `data` from this, since it may not be updated
  const [checkRuleUsed, { data: checkRuleUsedData }] = useLazyQuery<
    {
      checkRuleUsed?: CheckRuleUsedResponse;
    },
    QueryCheckRuleUsedArgs
  >(api.rules.checkRuleUsed);

  const [sendSlackNotification] = useMutation<
    {
      sendSlackNotification?: string;
    },
    MutationSendSlackNotificationArgs
  >(api.misc.sendSlackNotification);

  const [ruleToDefaultRule] = useMutation<
    {
      ruleToDefaultRule?: DefaultRule;
    },
    MutationRuleToDefaultRuleArgs
  >(api.defaultRules.ruleToDefaultRule);

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const isSuperUser = meData?.me?.isSuperuser || false;

  // modal
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  useEffect(() => void refetchMe(), []);

  useEffect(() => {
    if (transaction?.ruleUsed) {
      checkRuleUsed({
        variables: {
          ruleId: transaction.ruleUsed.id,
        },
      });
    }
  }, [transaction?.ruleUsed]);

  // Actually calls updateTransaction mutation
  const _updateTransactionHelper = async (
    values: FormValues,
    transactionId: string,
    overrideLabel: boolean
  ) => {
    const variables = {
      updates: {
        notes: values.notes,
        title: values.title,
        label: values.labelUsed,
        overrideLabel,
        globalRuleName: isGlobalRuleMode ? values.globalRuleName : "",
      },
      transactionId,
      createDefaultRule: isGlobalRuleMode,
    };

    try {
      const result = await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: "Successfully updated transaction",
        status: "success",
      });

      const message = result.data.updateTransaction.message;
      const messageStatus = result.data.updateTransaction.messageStatus;
      if (message && clientId) {
        const constraintsLink = getLink(
          clientId,
          {
            search: result.data.updateTransaction.transaction?.constraints,
          },
          true
        );
        console.log("CONSTRAINTS LINK: " + constraintsLink);
        setTimeout(() => {
          toast.show({
            message,
            status: messageStatus,
            onClick: message.includes("other transaction")
              ? () => window.open(constraintsLink, "_blank")
              : undefined,
          });
        }, 1250);
      }

      search.delete("transactionId");
      setSearchParams(search);
    } catch (err) {
      toast.show({
        message: (err as GraphQLError).message,
        status: "error",
      });
    }
  };

  // updateTransaction
  const _updateTransaction = async (values: FormValues) => {
    if (isLoadingUpdateTransaction) return;
    if (!transaction || !clientId) return;
    try {
      // if there is no rule or if label is not changing,
      // just call updateTransaction mutation
      if (
        !transaction.ruleUsed ||
        values.labelUsed === transaction?.labelUsed
      ) {
        await _updateTransactionHelper(values, transaction.id, false);
        return;
      }

      // error: couldn't pull data for this rule
      if (checkRuleUsedData?.checkRuleUsed === undefined) return;

      // error: checkRuleUsed is for wrong rule
      if (checkRuleUsedData?.checkRuleUsed.id !== transaction.ruleUsed.id)
        return;

      // error: couldn't calculate checkRule
      const numTxnsRuleUsed = checkRuleUsedData.checkRuleUsed.numTxnsRuleUsed;
      if (numTxnsRuleUsed === undefined) return;

      // if the rule applies to just this transaction, call updateTransaction
      if (numTxnsRuleUsed === 0 || numTxnsRuleUsed === 1) {
        _updateTransactionHelper(values, transaction.id, false);
      }
      // if the transaction applies to 2+ transactions AND they're changing the label, ask if they want to remove it
      else if (labelUsed) {
        // three options: one-off label, delete rule, cancel
        _showModal("OverrideLabelModal", {
          rule: transaction.ruleUsed,
          numTxnsRuleUsed,
          updateTransactionOverrideLabel: () =>
            _updateTransactionHelper(values, transaction.id, true),
          updateTransaction: () =>
            _updateTransactionHelper(values, transaction.id, false),
          isLoadingUpdateTransaction,
        });
      } else {
        // don't update the label used because they aint changing it
        _updateTransactionHelper(values, transaction.id, false);
      }
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Sorry, an error occurred.",
        status: "error",
      });
    }
  };

  const _onInvalid: SubmitErrorHandler<FormValues> = (values, e) => {
    console.log(values);
  };

  const { statusTagText, infoMessage } = useMemo(() => {
    // label used
    if (transaction?.labelUsed)
      return {
        statusTagText: "",
        infoMessage: "",
      };
    // rule used
    if (transaction?.ruleUsed) {
      const ruleType = transaction.ruleUsed?.type || "";
      return {
        statusTagText: formatRuleUsed(ruleType),
        infoMessage:
          "Awaken created a pattern to automatically handle this type of transaction.",
      };
    }
    // auto reviewed
    if (transaction?.autoReviewReason) {
      return {
        statusTagText: formatAutoReviewReason(transaction.autoReviewReason),
        infoMessage: "Awaken automatically reviewed this transaction.",
      };
    }
    return {
      statusTagText: "",
      infoMessage: "",
    };
  }, [transaction]);

  const onClickIncorrect = () => {
    if (!transaction) return;
    sendSlackNotification({
      variables: {
        message: `Transaction ${transaction.txnHash} (${transaction.id}) marked as incorrect for auto-review reason ${transaction.autoReviewReason}`,
      },
    });
    toast.show({
      message: "Thanks for your feedback! We will look into this problem.",
      status: "success",
    });
  };

  // LABELS
  const { header, text, background } = useTheme();

  const { data, loading } = useQuery<Pick<Query, "getTransactionTypeOptions">>(
    api.transactions.typeOptions,
    { variables: { transactionId: transaction?.id }, skip: !transaction }
  );

  const { setValue, watch } = useFormContext<FormValues>();
  const labelUsed = watch("labelUsed");

  const labelOptions: LabelOption[] = (
    data?.getTransactionTypeOptions?.labels || []
  )
    .filter(hasValue)
    // we don't want to create rules for internal transfer for all clients (i.e. global rule mode)
    .filter(
      (option) =>
        !isGlobalRuleMode || option.ruleType !== RuleTypeEnum.V1InternalTransfer
    )
    .map((option) => ({ ...option, isDisabled: !option.applicable }));

  const onSelectOption = (o: Maybe<TransactionTypeOption>) => {
    // if (o?.value === "v1:internal_transfer") {
    //   toast.show({
    //     message:
    //       "Only use the Wallet Transfer label if you cannot import your other wallet / exchange into Awaken",
    //     status: "info",
    //   });
    // }
    setValue("labelUsed", o?.value || null, { shouldDirty: true });
  };

  const { control } = useFormContext<FormValues>();

  const selectedOption = useMemo(() => {
    // Note: we have to do both and return label first. it is possible something is labeled AND a rule is applied.
    // we prioritize the label over the rule
    return labelOptions.find((o) => o.value === labelUsed);
  }, [labelOptions, labelUsed]);

  const hasExplorer =
    transaction?.blockExplorerName && transaction?.blockExplorerUrl
      ? true
      : false;
  const showExplorer =
    hasExplorer && (onlyHasRuleOrAutoReview ? canEdit && !showDropdown : true);
  // force recalculate if the txn is dirty and should recalc or the txn is marked as importing just in case they get in a case
  // where imports are done but dirty. shouldn't happen but just covering case so they don't have a crappy UX and don't know what the next step is
  const forceRecalculate =
    transaction?.isDirty === IsDirtyEnum.Recalculate ||
    transaction?.isImporting;

  const isLarge = useIsLargeScreen();

  const theme = useTheme();

  const createDefaultRuleFromRule = async () => {
    if (!transaction || !transaction.ruleUsed) return;
    if (transaction.ruleUsed.createdByAwaken) return;

    const label = window.prompt(
      "What's the label for this",
      transaction.ruleUsed.label
    );

    if (label === null) return; // cancel

    try {
      await ruleToDefaultRule({
        variables: {
          transactionId: transaction.id,
          label,
        },
      });

      toast.show({
        message: "Successfully created default rule",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error syncing transaction!",
      });
    }
  };

  return (
    <Box w="100%">
      <HStack
        width="100%"
        alignItems="center"
        justifyContent="flex-start"
        margin={"0.5rem 0"}
      >
        <Box
          flex={1}
          style={{
            display: "flex",
            flexDirection: isLarge ? "row" : "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {forceRecalculate && (
            <RecalculateButton message="You must press 'Recalculate' before modifying this transaction" />
          )}
          {!forceRecalculate && showDropdown && (
            <Box
              w="100%"
              display="flex"
              margin={isLarge ? "0" : "1rem"}
              alignItems="center"
            >
              <LabelSelect
                labelOptions={labelOptions}
                selectedOption={selectedOption}
                onSelectOption={onSelectOption}
                loading={loading}
              />

              {canEdit && (
                <Touchable
                  marginLeft="1rem"
                  iconName={showDropdown ? undefined : "fa-sharp fa-pen"}
                  iconPosition="right"
                  label={showDropdown ? "Cancel" : "Edit"}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              )}
            </Box>
          )}

          {!showDropdown && !forceRecalculate && onlyHasRuleOrAutoReview && (
            <Box
              display="flex"
              flexDir="column"
              justifyContent={"space-between"}
              alignItems="flex-start"
              w="100%"
            >
              <Box
                marginLeft={showDropdown ? "1rem !important" : "0"}
                display="flex"
                justifyContent={"space-between"}
                alignItems="flex-start"
              >
                <StatusTag
                  label={statusTagText}
                  type="success"
                  boxProps={{
                    border: `1px solid ${colors.positive}`,
                    marginRight: "0.5rem",
                  }}
                  infoIcon
                  hasBorder
                  infoMessage={infoMessage}
                />

                {canEdit && (
                  <Touchable
                    iconName={showDropdown ? undefined : "fa-sharp fa-pen"}
                    iconPosition="right"
                    label={showDropdown ? "Cancel" : "Edit"}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                )}
              </Box>
              {/* {onlyHasAutoReview && !showDropdown && isLarge && (
                <Box
                  color={theme.header}
                  w="100%"
                  textAlign="left"
                  marginTop="0.2rem"
                >
                  Looks wrong? Click{" "}
                  <span
                    onClick={onClickIncorrect}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <strong>here</strong>
                  </span>{" "}
                  to tell us.
                </Box>
              )} */}
              {usingRule &&
                !transaction.ruleUsed.createdByAwaken &&
                isSuperUser && (
                  <Box w="100%" textAlign="left" marginTop="0.2rem">
                    <Text
                      fontSize="sm"
                      cursor="pointer"
                      fontWeight="bold"
                      onClick={createDefaultRuleFromRule}
                    >
                      Create default rule out of rule
                    </Text>
                  </Box>
                )}
            </Box>
          )}
        </Box>
        {isLarge && (
          <SaveChangesButton
            isLarge={isLarge}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            _onInvalid={_onInvalid}
            _updateTransaction={_updateTransaction}
            transaction={transaction}
          />
        )}
      </HStack>
      {!forceRecalculate &&
        showDropdown &&
        transaction?.formattedFunctionName &&
        !transaction.formattedFunctionName.startsWith("0 X") && (
          <Text color={theme.header} fontSize="sm" marginBottom="0.5rem">
            Hint: The smart contract code said the word
            {transaction?.formattedFunctionName.split(" ").length > 1
              ? "s"
              : ""}{" "}
            <strong>"{transaction?.formattedFunctionName}"</strong> in it
          </Text>
        )}
      {!forceRecalculate && (
        <Recommendation
          labelOptions={labelOptions}
          selectedOption={selectedOption}
          onSelectOption={onSelectOption}
          _updateTransaction={_updateTransaction}
          _onInvalid={_onInvalid}
        />
      )}

      {isLarge &&
        !forceRecalculate &&
        showDropdown &&
        !!transaction?.blockExplorerUrl && (
          <Box margin="0" marginTop="0.5rem">
            <Text color={header} fontSize="sm">
              Don't remember what this transaction is?{" "}
              <a
                style={{ color: colors.primary, fontWeight: "bold" }}
                target="_blank"
                href={transaction?.blockExplorerUrl || ""}
              >
                Check it out in {transaction?.blockExplorerName}{" "}
                <i
                  style={{ marginLeft: "0.25rem" }}
                  className="fa-sharp fa-external-link-alt"
                />
              </a>
            </Text>
          </Box>
        )}
      {isGlobalRuleMode && (
        <Input
          name="globalRuleName"
          control={control}
          label="Protocol name (Global rule name)"
          placeholder="LooksRare (NOT LooksRare Staking)"
        />
      )}

      {!isLarge && (
        <SaveChangesButton
          isLarge={isLarge}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          _onInvalid={_onInvalid}
          _updateTransaction={_updateTransaction}
          transaction={transaction}
        />
      )}
    </Box>
  );
};

const Recommendation = ({
  labelOptions,
  selectedOption,
  onSelectOption,
  _updateTransaction,
  _onInvalid,
}: {
  labelOptions: LabelOption[];
  selectedOption?: LabelOption;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  _updateTransaction: (values: FormValues) => void;
  _onInvalid: SubmitErrorHandler<FormValues>;
}) => {
  const { handleSubmit } = useFormContext<FormValues>();
  const { transaction } = useContext(ActiveTransactionContext);
  const [getOtherUserLabels, { data: otherUserLabels }] = useLazyQuery<
    {
      getOtherUserLabels?: GetOtherUserLabelsResp;
    },
    QueryGetOtherUserLabelsArgs
  >(api.rules.getOtherUserLabels);
  const isSolana = transaction?.provider === "solana";

  // modal
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  useEffect(() => {
    if (
      // only make API request if the transaction is not reviewed
      transaction &&
      transaction?.reviewStatus !== LedgerTransactionReviewStatusEnum.Reviewed
    )
      getOtherUserLabels({
        variables: {
          transactionId: transaction.id,
        },
      });
  }, [transaction]);

  const probablyWalletTransfer = useMemo(() => {
    if (!transaction) return false;
    return transaction.probablyWalletTransfer;
  }, [transaction]);

  const isLikelySpam = useMemo(() => {
    if (!transaction) return false;
    if (transaction.transfers.length !== 1) return false; // one transfer
    if (!transaction.transfers[0].toAccountId) return false; // to you
    if (transaction.transfers[0].fiatAmountCents !== 0) return false; // no price data
    const symbol: string =
      transaction.transfers[0]?.fullAsset?.symbol?.toLowerCase() || "";
    const matches =
      symbol.match(/[a-z0-9]+\.com/i) || // cant combine these for some reason without breaking the algo
      symbol.match(/[a-z0-9]+\.fi/i) ||
      symbol.match(/[a-z0-9]+\.net/i) ||
      symbol.match(/[a-z0-9]+\.org/i) ||
      symbol.match(/[a-z0-9]+\.xyz/i) ||
      [];
    return matches.length > 0;
  }, [transaction]);

  // too computationally intensive, probably slowing down the app
  const recommendation = useMemo(() => {
    return null;
    // if (selectedOption) return null;
    // if (!otherUserLabels?.getOtherUserLabels) return null;
    // if (
    //   !otherUserLabels.getOtherUserLabels.ruleType ||
    //   !otherUserLabels.getOtherUserLabels.shouldUse
    // )
    //   return null;
    // const ruleType = otherUserLabels.getOtherUserLabels.ruleType;
    // const option = labelOptions.find((o) => o.ruleType === ruleType);
    // if (!option || !option.applicable) return null;
    // return {
    //   option,
    //   numUsers: otherUserLabels.getOtherUserLabels.numUsers,
    // };
  }, [otherUserLabels, labelOptions, selectedOption, transaction]);

  const theme = useTheme();

  const onClickRecommendation = useCallback(
    (option?: Maybe<TransactionTypeOption>) => {
      if (option) {
        onSelectOption(option);
        handleSubmit(_updateTransaction, _onInvalid)();
      }
    },
    [
      recommendation,
      handleSubmit,
      onSelectOption,
      _updateTransaction,
      _onInvalid,
    ]
  );

  if (transaction?.reviewStatus === LedgerTransactionReviewStatusEnum.Reviewed)
    return null;

  if (probablyWalletTransfer) {
    // wallet transfer
    return null;
    // return (
    //   <Text color={theme.text} fontSize="sm" marginTop="0.5rem">
    //     Sent to your other wallet?{" "}
    //     <Text
    //       cursor="pointer"
    //       userSelect={"none"}
    //       display="inline"
    //       fontSize="sm"
    //       fontWeight="bold"
    //       color={colors.primary}
    //       onClick={() =>
    //         _showModal("AccountModal", {
    //           location: "wallet_transfer_hint",
    //         })
    //       }
    //     >
    //       Connect your wallet
    //     </Text>
    //   </Text>
    // );
  }

  if (isLikelySpam) {
    // spam
    return (
      <Text fontSize="sm" marginTop="0.5rem">
        {/* {recommendation.numUsers} other users labeled this transaction as{" "} */}
        This transaction looks like spam.{" "}
        <Text
          cursor="pointer"
          userSelect={"none"}
          display="inline"
          fontSize="sm"
          fontWeight="bold"
          color={colors.primary}
          onClick={() =>
            onClickRecommendation(
              labelOptions.find((o) => o.value === "v1:spam")
            )
          }
        >
          Mark as 🗑 Spam
        </Text>
      </Text>
    );
  }

  // if (recommendation) {
  //   // recommendation
  //   return (
  //     <Text fontSize="sm" marginTop="0.5rem">
  //       {/* {recommendation.numUsers} other users labeled this transaction as{" "} */}
  //       Other users labeled this transaction as{" "}
  //       <Text
  //         cursor="pointer"
  //         userSelect={"none"}
  //         display="inline"
  //         fontSize="sm"
  //         fontWeight="bold"
  //         color={colors.primary}
  //         onClick={
  //           recommendation
  //             ? () => onClickRecommendation(recommendation.option)
  //             : undefined
  //         }
  //       >
  //         {recommendation.option.label}
  //       </Text>
  //     </Text>
  //   );
  // }

  return null;
};

const TransactionDetails = () => {
  const { clientId } = useParams();
  const { transaction, showAssetTransfersBuilder, client } = useContext(
    ActiveTransactionContext
  );
  const { header, background, border, medBackground } = useTheme();

  const { control } = useFormContext<FormValues>();
  const navigate = useNavigate();

  const { field: titleField } = useController<FormValues, "title">({
    name: "title",
    control,
  });
  const { field: notesField } = useController<FormValues, "notes">({
    name: "notes",
    control,
  });

  const isLarge = useIsLargeScreen();

  if (!transaction) {
    return null;
  }

  const timezone = client?.timezone || "UTC";

  return (
    <Box padding="0.25rem 1rem" bg={background}>
      <Container
        bg={background}
        padding="0px"
        marginTop="0.75rem !important"
        display="block"
      >
        <Box display="flex">
          {/* <Box width="18rem">
            <Input
              {...titleField}
              control={control}
              label="Name"
              placeholder="NFT BAYC #1043 Buy"
              isDisabled
            />
          </Box> */}
          <Box flex={1}>
            <FormLabel color={header} fontSize={14}>
              Notes
            </FormLabel>
            <Textarea
              {...notesField}
              control={control}
              textareaProps={{
                bg: background,
                style: {
                  color: header,
                  fontSize: 14,
                  borderColor: border,
                  padding: "0.5rem",
                },
              }}
              placeholder="Start typing notes..."
            />
          </Box>
        </Box>
      </Container>

      {/* <TensorWarningMessage transaction={transaction} /> */}
      {/* <StakingMessage transaction={transaction} /> */}
      {/* <Divider style={{ margin: "1rem 0" }} /> */}
      <IncomeMessage
        transaction={transaction}
        currency={transaction?.fiatCurrency || "USD"}
      />
      <Box
        padding="0"
        paddingBottom="2rem"
        overflowX={isLarge ? "auto" : "scroll"}
      >
        <TransferOverview />
        {showAssetTransfersBuilder && <Transfers />}
        <UnhideTransfers
          transactionId={transaction.id}
          hasHiddenTransfers={transaction.hasHiddenTransfers || false}
        />
        <Fees />
      </Box>
    </Box>
  );
};

const _includes = (_str: string, options: string[]) => {
  const str = _str.trim().toLowerCase();
  return options.some((option) => str.includes(option));
};

const IncomeMessage = ({
  transaction,
  currency,
}: {
  transaction: BaseFullTransactionFields;
  currency: Currency;
}) => {
  const toast = useMyToast();
  const theme = useTheme();
  const [updateTransaction] = useMutation(api.transactions.update);

  const hasIncomeOverride =
    !isNil(transaction.overrideIncomeCents) &&
    transaction.overrideIncomeCents > 0;

  const canEditIncome =
    transaction.labelUsed === "v1:rewards_income" ||
    transaction.labelUsed === "v1:staking" ||
    transaction.labelUsed === "v1:lend" ||
    transaction.labelUsed === "v1:unstaking_with_rewards" ||
    transaction.labelUsed === "v1:unstaking" ||
    transaction.ruleUsed?.type === "v1:staking" ||
    transaction.ruleUsed?.type === "v1:unstaking_with_rewards" ||
    transaction.ruleUsed?.type === "v1:rewards_income" ||
    transaction.ruleUsed?.type === "v1:unstaking" ||
    transaction.ruleUsed?.type === "v1:lend";

  const _onEditIncome = async () => {
    const val = hasIncomeOverride
      ? D(transaction.overrideIncomeCents || 0).toFormat("0.00")
      : D(parseFloat(String(transaction.incomeSum)), currency).toFormat("0.00");

    const newIncomeAmount = window.prompt(
      "What would you like the income to be (USD)? We'd recommend adding a note to this transaction explaining why you are editing this value (for your record).",
      val
    );

    if (!newIncomeAmount) {
      return;
    }

    // newIncomeAmount
    const num = new BigNumber(newIncomeAmount);

    if (num.isNaN()) {
      alert("Invalid number");
      return;
    }

    try {
      const variables: MutationUpdateTransactionArgs = {
        transactionId: transaction.id,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          overrideIncomeCents: num.multipliedBy(100).dp(0).toNumber(),
          txnHash: transaction.txnHash,
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: "Updated transaction hash!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error deleting transaction!",
      });
    }
  };

  if (!transaction.incomeSum) return <div />;
  if (new BigNumber(transaction.incomeSum).isZero()) return <div />;

  return (
    <HStack
      style={{
        marginTop: "1.5rem",
      }}
    >
      <Text
        fontWeight="normal"
        fontSize="sm"
        textAlign="left"
        color={theme.header}
      >
        You earned{" "}
        <strong>
          {hasIncomeOverride
            ? D(transaction.overrideIncomeCents || 0).toFormat()
            : D(parseFloat(String(transaction.incomeSum)), currency).toFormat()}
        </strong>{" "}
        of income on this transaction.{" "}
        <Info message="Looks wrong? Hit the Recalculate button to update this number." />{" "}
      </Text>
      {canEditIncome && (
        <Touchable
          onClick={_onEditIncome}
          label="Edit Income"
          iconName="fa-sharp fa-pen"
        />
      )}
    </HStack>
  );
};

function StakingMessage({
  transaction,
}: {
  transaction?: Maybe<BaseFullTransactionFields>;
}) {
  const theme = useTheme();

  if (!transaction) return null;
  if (!transaction.title) return null;

  const processingType = transaction.processingType;

  let text = "";

  if (_includes(processingType || "", ["staking", "restake"]))
    text =
      "Awaken is the only crypto tax software that handles staking correctly. Unlike other products that treat stakes as sales and unstakes as 100% income, Awaken understands staking, and this can often result in tax savings!";
  // commented this out because it's not true anymore:
  // else if (
  //   _titleIncludes(transaction.title, [
  //     "uniswap v3 lp increase liquidity",
  //     "uniswap v3 lp mint",
  //   ])
  // )
  //   text =
  //     "Awaken is the only crypto tax software that handles Uniswap V3 LP correctly. Other tax products just see your LP position leaving your wallet, so they treat it as a sale instead of liquidity providing. That means they make you pay capital gains tax every time you add liquidity!";

  if (!text) return null;

  return (
    <HStack
      style={{
        borderRadius: 5,
        padding: "1rem",
        backgroundColor:
          theme.theme === "light" ? colors.green100 : colors.green10,
        border: "1px solid " + colors.green50,
      }}
      alignItems="flex-start"
    >
      <i
        className="fa-sharp fa-info-circle"
        style={{
          color: colors.green50,
          fontSize: 18,
          marginRight: "0.5rem",
          position: "relative",
          top: 2,
        }}
      />
      <Text
        color={colors.green50}
        fontWeight="normal"
        fontSize="sm"
        w="100%"
        textAlign="left"
      >
        {text}
      </Text>
    </HStack>
  );
}

function TensorWarningMessage({
  transaction,
}: {
  transaction?: Maybe<BaseFullTransactionFields>;
}) {
  if (!transaction) return null;
  if (!transaction.title) return null;

  const processingType = transaction.processingType;

  const text = "";

  // if (transaction.title.toLowerCase().includes("tensor"))
  //   text =
  //     "Awaken is currently having trouble parsing some Tensor transactions. A fix will be released by the end of January.";

  if (!text) return null;

  return (
    <HStack
      style={{
        borderRadius: 5,
        padding: "1rem",
        backgroundColor: colors.yellow100,
        border: "1px solid " + colors.yellow50,
      }}
      alignItems="flex-start"
    >
      <i
        className="fa-sharp fa-info-circle"
        style={{
          color: colors.black,
          fontSize: 18,
          marginRight: "0.5rem",
          position: "relative",
          top: 2,
        }}
      />
      <Text
        color={colors.black}
        fontWeight="normal"
        fontSize="sm"
        w="100%"
        textAlign="left"
      >
        {text}
      </Text>
    </HStack>
  );
}

function Header({ handleHide }: { handleHide: () => void }) {
  const { transaction, showAssetTransfersBuilder, client } = useContext(
    ActiveTransactionContext
  );
  const {
    background,
    secondaryBackground,
    medBackground,
    header,
    border,
    text,
  } = useTheme();
  const dispatch = useDispatch();
  const transactionId = transaction?.id || "";

  const clientId = transaction?.clientId;
  const toast = useMyToast();
  const clipboard = useClipboard(transaction?.id || "");
  const [syncTransaction] = useMutation(api.transactions.sync);
  const [hideTransaction] = useMutation(api.transactions.hide);
  const [updateTransaction] = useMutation(api.transactions.update);

  const [getTransactionPage, { data: getTransactionPageData }] = useLazyQuery<
    {
      getTransactionPage?: TransactionPageResponse;
    },
    QueryGetTransactionPageArgs
  >(api.transactions.getPage, {
    fetchPolicy: "network-only",
  });

  const _copyConstraints = () => {
    navigator.clipboard.writeText(transaction?.constraints || "");
    toast.show({ message: "Copied Constraints!", status: "info" });
  };

  const _copyTransactionId = () => {
    navigator.clipboard.writeText(transaction?.id || "");
    toast.show({ message: "Copied Transaction ID!", status: "info" });
  };

  const _deleteTransaction = async () => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to delete this transaction? You'll need to email team@awaken.tax to restore it if you want to un-delete it later."
      );

      if (!confirm) {
        return;
      }

      const variables: MutationHideTransactionArgs = {
        transactionId,
        isHidden: true,
      };

      await hideTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: "Deleted Transaction!",
        status: "success",
      });

      handleHide();
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error deleting transaction!",
      });
    }
  };

  const _updateTransaction = async () => {
    try {
      const newHash = window.prompt(
        "What would you like to set the transaction hash to?",
        transaction?.txnHash || ""
      );

      if (!newHash) {
        return;
      }

      const variables: MutationUpdateTransactionArgs = {
        transactionId,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          txnHash: newHash,
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: "Updated transaction hash!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error updating transaction hash!",
      });
    }
  };

  const _updateTransactionDate = async () => {
    try {
      if (!transaction?.createdAt) {
        toast.show({
          status: "error",
          message: "Transaction date is missing!",
        });
        return;
      }

      const newDate = window.prompt(
        "Edit the date of the transaction (ISO format)",
        new Date(transaction?.createdAt)?.toISOString() || ""
      );

      if (!newDate) {
        return;
      }

      const isValidDate = moment(newDate).isValid();

      if (!isValidDate) {
        toast.show({
          status: "error",
          message: "Invalid date format",
        });
        return;
      }

      const variables: MutationUpdateTransactionArgs = {
        transactionId,
        createDefaultRule: false,
        updates: {
          overrideLabel: false,
          createdAt: new Date(newDate),
          label: transaction?.labelUsed || null,
          title: transaction?.title || "",
          notes: transaction?.notes || "",
          globalRuleName: null,
        },
      };

      await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: "Updated transaction date!",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error updating transaction date!",
      });
    }
  };

  const _hardSyncTransaction = async () => {
    try {
      await syncTransaction({
        variables: { transactionId: transaction?.id || "" },
        refetchQueries: [api.transactions.retrieve],
      });

      toast.show({
        message:
          "Synced Transaction! You will need to run 'Recalculate' to update the gain/loss.",
        status: "success",
      });

      handleHide();

      // setTimeout(() => {
      //   dispatch(
      //     show("TxnDetailModal", {
      //       transactionId,
      //     })
      //   );
      // }, 1500);
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: (err as any)?.message || "Error syncing transaction!",
      });
    }
  };

  const onClickTransactionOrder = async () => {
    if (!transaction || !clientId) return;

    const pageData = await getTransactionPage({
      variables: {
        transactionId: transaction.id,
      },
    });
    const page33 = pageData.data?.getTransactionPage?.page;
    if (page33 === undefined) return;

    window.open(
      getLink(
        clientId,
        {
          page: page33,
          highlightTransactionId: transaction.id,
          includeSpam: true,
        },
        true
      ),
      "_blank"
    );
  };

  const createdAt = useCreatedAtUTC(
    transaction ?? null,
    client?.timezone || "UTC"
  );

  const isLarge = useIsLargeScreen();

  if (!transaction) {
    return null;
  }

  const timezone = client?.timezone || "UTC";
  const createdAtMoment = moment.tz(transaction.createdAt, timezone);

  // console.log(transaction.needsReview);

  return (
    <VStack
      w="100%"
      marginTop="0rem"
      bg={medBackground}
      style={{
        padding: "1.5rem 1.5rem",
      }}
    >
      <HStack
        display="flex"
        alignItems="flex-start"
        w="100%"
        paddingRight="2.5rem"
      >
        <VStack flex={1} alignItems="flex-start">
          <HStack marginBottom="0.5rem" w="100%">
            <HStack
              style={{
                padding: "0.5rem",
                paddingRight: "1rem",
                border: `1px solid ${border}`,
                borderRadius: 8,
                backgroundColor: secondaryBackground,
              }}
            >
              <Image
                src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
                width="1.25rem"
                height="1.25rem"
                display="inline"
                style={{ borderRadius: 5 }}
              />
              <Text color={header} fontSize="sm" fontWeight="semibold">
                {INTEGRATIONS.find((i) => i.provider === transaction.provider)
                  ?.name || "Provider"}
              </Text>
            </HStack>

            <Box
              onClick={() => {
                toast.show({
                  message: "Copied transaction link!",
                  status: "info",
                });

                navigator.clipboard.writeText(window.location.href);
              }}
              style={{
                padding: "0.5rem",
                paddingRight: "1rem",
                border: `1px solid ${border}`,
                borderRadius: 8,
                color: text,
                fontSize: 14,
                fontWeight: "500",
                fontStretch: "normal",
                cursor: "pointer",
                backgroundColor: secondaryBackground,
              }}
            >
              Copy {isMobile ? "link" : "transaction link"}{" "}
              <i style={{ marginLeft: 5 }} className="fa-sharp fa-clone" />
            </Box>
          </HStack>

          <Text
            // w="100%"
            noOfLines={4}
            fontWeight="semibold"
            fontSize={isLarge ? "2xl" : "md"}
            marginTop="0.3rem"
            // wrap the text
            color={header}
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              hyphens: "auto",
            }}
          >
            {(transaction?.title || "").replace(/_/g, " ")}
          </Text>

          {transaction.autoReviewReason?.startsWith("v1:default_rule") && (
            <StatusTag
              label="🧠 Labeled by Awaken AI"
              // iconName="fa-sharp fa-user-robot"
              type="info"
              infoMessage="Awaken automatically labeled this transaction."
              iconStyle={{ fontSize: 13 }}
              boxProps={{
                style: { border: "1px solid " + colors.lightBlue50 },
                margin: "0.3rem 0",
              }}
            />
          )}

          <Box
            color={colors.gray4}
            style={{
              fontSize: 16,
              fontWeight: "normal",
              width: "100%",
              marginRight: "2rem",
              marginTop: "1.25rem",
              display: "flex",
              alignItems: isLarge ? "center" : "inherit",
              flexDirection: isLarge ? "row" : "column",
            }}
          >
            <Tooltip label={timeAgo(createdAt as DateTime)}>
              <Text
                fontWeight="500"
                fontSize="sm"
                color={text}
                marginTop={isLarge ? "0" : "1rem"}
              >
                {createdAtMoment.format("MMM Do, YYYY h:mm A z")}
              </Text>
            </Tooltip>

            {isLarge && (
              <Text style={{ margin: "0 1rem", color: text }}>|</Text>
            )}

            {transaction?.txnHash && (
              <HStack
                marginLeft="0"
                marginTop={isLarge ? 0 : "10px"}
                justifyContent={"flex-start"}
              >
                <Box>
                  <Text
                    textAlign="left"
                    fontSize="sm"
                    fontWeight="500"
                    color={text}
                    isTruncated
                  >
                    {truncate(transaction.txnHash, { length: 30 })}
                  </Text>
                </Box>
                <Copy
                  value={transaction.txnHash || ""}
                  iconStyle={{
                    padding: "0",
                    fontSize: "14px",
                  }}
                  // label={transaction.txnHash}
                />
              </HStack>
            )}

            {transaction?.txnHash && isLarge && (
              <Text style={{ margin: "0 1rem", color: text }}>|</Text>
            )}

            {isLarge && (
              <StatusTag
                type={
                  transaction?.isHidden
                    ? "error"
                    : GET_TXN_STATUS[
                        transaction?.status ||
                          LedgerTransactionStatusEnum.Pending
                      ]
                }
                hasBorder={true}
                label={
                  transaction?.isHidden
                    ? "Deleted"
                    : transaction?.status || "None"
                }
              />
            )}
          </Box>
        </VStack>
      </HStack>

      <HStack
        style={{
          // hack to line up the items a lil better
          position: "relative",
          left: -11,
          marginTop: "0.5rem",
        }}
        w="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <TransactionExplorerButton
          blockExplorerName={transaction?.blockExplorerName}
          blockExplorerUrl={transaction?.blockExplorerUrl}
          txnHash={transaction?.txnHash || ""}
          provider={transaction?.provider}
        />

        {isLarge && (
          <>
            <Touchable
              onClick={onClickTransactionOrder}
              label="Open in timeline"
              padding="0.55rem 0.75rem"
              iconName="fa-sharp fa-list-timeline"
            />

            <EntriesPopover />

            <ActionSheet
              content={{
                maxW: "225px",
              }}
              popover={{ placement: "bottom", trigger: "hover" }}
              commands={[
                {
                  label: "Delete Transaction",
                  iconName: "fa-sharp fa-trash",
                  onClick: _deleteTransaction,
                  iconColor: colors.red50,
                  color: colors.red50,
                  infoMessage:
                    "This will remove this transaction from Awaken. If you need to un-remove it, you will have to message support and let us know and we can help you.",
                },
                {
                  label: "Update Hash",
                  iconName: "fa-sharp fa-pen",
                  onClick: _updateTransaction,
                  infoMessage: "Only useful for blockchain transactions.",
                },
                {
                  label: "Edit Date",
                  iconName: "fa-sharp fa-calendar",
                  onClick: _updateTransactionDate,
                },
                {
                  label: "Copy Txn ID",
                  iconName: "fa-sharp fa-clone",
                  onClick: _copyTransactionId,
                },
                {
                  label: "Copy Constraints",
                  iconName: "fa-sharp fa-clone",
                  onClick: _copyConstraints,
                },
                {
                  label: "Sync Transaction",
                  iconName: "fa-sharp fa-refresh",
                  onClick: _hardSyncTransaction,
                  infoMessage:
                    "This will re-sync this specific transaction with Awaken's most recent import data. This is useful if you've imported a transaction that was missing transfers when you initially imported but Awaken now supports.",
                },
              ].filter(hasValue)}
            >
              <Touchable
                // super hacky can't figure out why this one is taller so whatever
                padding="0.45rem 0.75rem"
                iconName="fa-sharp fa-ellipsis-v"
                label="More"
                iconStyle={{ fontSize: 14 }}
              />
            </ActionSheet>
          </>
        )}
      </HStack>
    </VStack>
  );
}

type TransactionExplorerButtonProps = {
  blockExplorerUrl?: Maybe<string>;
  blockExplorerName?: Maybe<string>;
  txnHash: string;
  provider?: Maybe<string>;
};

const TransactionExplorerButton = ({
  blockExplorerUrl,
  blockExplorerName,
  txnHash,
  provider,
}: TransactionExplorerButtonProps) => {
  if (!blockExplorerName || !blockExplorerUrl) return null;

  if (provider === "solana") {
    return (
      <ActionSheet
        content={{
          maxW: "225px",
        }}
        popover={{ placement: "bottom", trigger: "hover" }}
        commands={[
          {
            label: "View in Solscan",
            iconImageSrc: "https://assets.awaken.tax/icons/solscan.png",
            link: `https://solscan.io/tx/${txnHash}`,
          },
          {
            label: "View in XRAY",
            onClick: noop,
            iconImageSrc: "https://assets.awaken.tax/icons/helius.png",
            link: `https://xray.helius.xyz/tx/${txnHash}`,
          },
          {
            label: "View in SolanaFM",
            iconImageSrc: "https://assets.awaken.tax/icons/solanafm.png",
            link: `https://solana.fm/tx/${txnHash}?cluster=mainnet-alpha`,
          },
        ]}
      >
        <Touchable
          padding="0.55rem 0.75rem"
          iconName="fa-sharp fa-external-link-alt"
          label={`View in block explorer`}
          style={{ display: "block" }}
        />
      </ActionSheet>
    );
  }

  if (provider === "bitcoin") {
    return (
      <ActionSheet
        content={{
          maxW: "225px",
        }}
        popover={{ placement: "bottom", trigger: "hover" }}
        commands={[
          {
            label: "View in Blockchair",
            iconImageSrc: "https://assets.awaken.tax/icons/btc.png",
            link: `https://blockchair.com/bitcoin/transaction/${txnHash}`,
          },
          {
            label: "View in Mempool",
            iconImageStyle: { borderRadius: 5 },
            iconImageSrc: "https://assets.awaken.tax/icons/mempool.png",
            link: `https://mempool.space/tx/${txnHash}`,
          },
          {
            label: "View in Ordiscan",
            iconImageStyle: { borderRadius: 5 },
            iconImageSrc: "https://assets.awaken.tax/icons/ordiscan.png",
            link: `https://ordiscan.com/tx/${txnHash}`,
          },
        ]}
      >
        <Touchable
          padding="0.55rem 0.75rem"
          iconName="fa-sharp fa-external-link-alt"
          label={`View in block explorer`}
          style={{ display: "block" }}
        />
      </ActionSheet>
    );
  }

  return (
    <Link
      href={blockExplorerUrl}
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{ padding: 0, textDecoration: "none" }}
    >
      <Touchable
        padding="0.55rem 0.75rem"
        iconName="fa-sharp fa-external-link-alt"
        label={`View on ${blockExplorerName}`}
        style={{ display: "block" }}
      />
    </Link>
  );
};

export const TxnDetailModal = connectModal({
  name: "TxnDetailModal",
})(_TxnDetailModal);
