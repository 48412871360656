import React, { ReactNode, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import NavBar from "src/components/NavBar";
import { Box, BoxProps, useDisclosure } from "@chakra-ui/react";
import { colors } from "src/theme";
import { Sidebar } from "src/components/Sidebar";
import {
  AccountFileUploadModal,
  AssetModal,
  CreateAccountModal,
} from "../modals";
import { WalletAccountModal } from "../modals/WalletAccountModal/WalletAccountModal";
import { TxnDetailModal } from "../modals/TxnDetailModal";
import { AccountModal } from "../modals/AccountModal";
import { CreateGlobalRuleModal } from "../modals/CreateGlobalRuleModal";
import Recalculating from "../Recalculating";
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { OverrideLabelModal } from "../modals/OverrideLabelModal";
import Banner, { BANNER_HEIGHT_PX } from "../styled/Banner";
import {
  getHasBanner,
  getIsGlobalRuleMode,
  setGlobalRuleMode,
  setHasBanner,
  setShowConfetti,
} from "src/redux/reducers/globalState";
import { useDispatch, useSelector } from "react-redux";
import { AwakenCSVUploadModal } from "../modals/AwakenCSVUploadModal";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Query, QueryPacksForClientArgs } from "src/api/generated/types";
import { api } from "src/api";
import { Copy } from "../styled";
import { CheckoutModal } from "../modals/CheckoutModal";
import { FreeBanner } from "../banners/FreeBanner";
import { GlobalRuleBanner } from "../banners";
import { ReferralCodeModal } from "../modals/ReferralCodeModal";
import { compose } from "lodash/fp";
import { pusher } from "src/utils/pusher";
import { useClientListener } from "src/hooks/useClientListener";
import { LabelsModal } from "../modals/LabelsModal";
import { show } from "redux-modal";
import { ReportExportWarningModal } from "../modals/ReportExportWarningModal";
import { PaymentModal } from "../modals/PaymentModal";
import { WalletTransferWarningModal, UnstakingWarningModal } from "../modals";
import { StakingExactWarningModal } from "../modals/StakingExactWarningModal";
import { useClientPoller } from "src/hooks/useClientPoller";
import { PricedAssetModal } from "../modals/PricedAssetModal";
import { setHighlightedTransactionId } from "src/redux/reducers/active";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { Touchable } from "../Touchable";
import { MobileHeader } from "../MobileHeader";
import { UpgradeInfoModal } from "../modals/UpgradeInfoModal";
import { PurchaseUpgradeModal } from "../PurchaseUpgradeModal";
import { PhoneVerificationModal } from "../modals/2FA/PhoneVerificationModal";
import { LoginModal } from "../modals/2FA/LoginModal";
import { VerifyEmailModal } from "../modals/2FA/VerifyEmailModal";
import { ClientNameModal } from "../modals/ClientNameModal";
import { useClientById, useMe } from "src/hooks";
import { WalletModal } from "../modals/WalletModal";
import {
  getAccountsLink,
  getBaseLink,
  getPortfolioLink,
} from "src/modules/ledger/transactions";
import { DeleteUserModal } from "../modals/DeleteUserModal";
import Loading from "src/views/Loading";
import { CoinbaseDiscountModal } from "../modals/CoinbaseDiscountModal";
import { useTheme } from "src/hooks/useTheme";
import { GMEDiscountModal } from "../modals/GMEDiscountModal";
import { MagicEdenDiscountModal } from "../modals/MagicEdenDiscountModal";
import { CXModal } from "../modals/CXModal";
import { TransferModal } from "../modals/TransferModal";
import { CreateTxnModal } from "../modals/CreateTxnModal";
import { ReferralModal } from "../modals/ReferralModal";
import { VIPQuoteRequestModal } from "../modals/VIPQuoteRequestModal";
import { CompetitorCreditModal } from "../modals/CompetitorCreditModal";
import { TOTPModal } from "../modals/2FA/TOTPModal";

const CONTENT_MARGIN_LEFT = 2.5;
const CONTENT_MARGIN_TOP = "2rem";

const Content = styled.div`
  width: ${100 - CONTENT_MARGIN_LEFT - CONTENT_MARGIN_LEFT}%;
  margin-left: ${CONTENT_MARGIN_LEFT}%;
  margin-top: ${CONTENT_MARGIN_TOP}%;
  overflow-y: scroll;
`;

function PageTemplateContainer(props: BoxProps) {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      bgColor={theme.medBackground}
      style={{
        overflow: "hidden",
      }}
      {...props}
    >
      <PhoneVerificationModal />
      <TOTPModal />
      {props.children}
    </Box>
  );
}

type NavBarPageTemplateProps = BoxProps & {
  children: ReactNode;
  position?: string;
  button?: string;
  wrapInMainScreen?: boolean;
};

function NavBarPageTemplate({ button, ...props }: NavBarPageTemplateProps) {
  return (
    <PageTemplateContainer {...props}>
      <NavBar position={props.position} button={button} />
      <Content>{props.children}</Content>
    </PageTemplateContainer>
  );
}

const MainScreen = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 1.5rem 3rem;
  padding-bottom: 5rem;
  display: block;
  overflow-y: scroll;
  @media (max-width: 800px) {
    padding: 4rem 0.75rem;
  }
`;

function SideBarPageTemplate(props: NavBarPageTemplateProps) {
  const { clientId } = useParams<{ clientId: string }>();
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearchParams] = useSearchParams();
  const transactionId = search.get("transactionId");
  const queryHighlightTransactionId = search.get("highlightTransactionId");
  const showUpgradeModal = search.get("showUpgradeModal") === "true";
  const location = useLocation();

  const showBanner = useSelector(getHasBanner);
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const wrapInMainScreen = props.wrapInMainScreen ?? true;
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const { background } = useTheme();

  const { me } = useMe();

  // listens
  useClientListener(clientId || null);
  // fallback clears the client cache so we have to refetch it every 60 seconds
  useClientPoller();
  const isLarge = useIsLargeScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const contentHeight = !isLarge
    ? "100%"
    : `calc(100vh - ${showBanner ? BANNER_HEIGHT_PX : 0}px)`; // - NAVBAR_HEIGHT

  useEffect(() => {
    if (!client) return;

    if (!client?.name) {
      _showModal("ClientNameModal");
    }
  }, [client]);

  useEffect(() => {
    if (!transactionId) {
      return;
    }

    // // delete the txn id
    // search.delete("transactionId");
    // setSearchParams(search);

    // show modal if there is a transactionId
    if (transactionId) {
      _showModal("TxnDetailModal", {
        transactionId,
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (!showUpgradeModal) return;
    dispatch(show("UpgradeInfoModal"));
  }, [showUpgradeModal]);

  useEffect(() => {
    dispatch(setHighlightedTransactionId(queryHighlightTransactionId));
  }, [queryHighlightTransactionId]);

  // portfolio redirect
  if (!me || !clientId) return null;
  // if (
  //   me &&
  //   me.hasPortfolioEnabled &&
  //   clientId &&
  //   // only if they're on the base link, not if they actually clicked the portfolio tab
  //   (window.location.pathname === getBaseLink(params.clientId || "") ||
  //     window.location.pathname === `${getBaseLink(params.clientId || "")}/`)
  // ) {
  //   return <Navigate to={getAccountsLink(clientId)} />;
  // }

  const isPortfolio =
    location.pathname === `/clients/${clientId}/portfolio` ||
    location.pathname === `/clients/${clientId}/portfolio/` ||
    location.pathname === `/clients/${clientId}/dashboard` ||
    location.pathname === `/clients/${clientId}`;

  return (
    <PageTemplateContainer {...props}>
      {/* modals */}

      <VIPQuoteRequestModal />
      <CompetitorCreditModal />
      <VerifyEmailModal />
      <LoginModal />
      <CXModal />
      <TxnDetailModal />
      <AssetModal />
      <CreateTxnModal />
      <TransferModal />
      <ReferralCodeModal />
      <WalletTransferWarningModal />
      <UnstakingWarningModal />
      <StakingExactWarningModal />
      <ReportExportWarningModal />
      <AccountModal />
      <OverrideLabelModal />
      <CreateAccountModal />
      <WalletAccountModal />
      <CreateGlobalRuleModal />
      <AwakenCSVUploadModal />
      <AccountFileUploadModal />
      <CheckoutModal />
      <PaymentModal />
      <UpgradeInfoModal />
      <PurchaseUpgradeModal />
      <LabelsModal />
      {/* <PricedAssetModal /> */}
      <ClientNameModal />
      <DeleteUserModal />
      <WalletModal />
      {/* <CoinbaseDiscountModal />
      <GMEDiscountModal />
      <MagicEdenDiscountModal /> */}
      <ReferralModal />

      {/* components */}
      <Recalculating />
      <PageBanner />

      {/* page content */}

      <MobileHeader />

      <Box
        height={contentHeight}
        minH={isLarge ? undefined : "100vh"}
        w="100%"
        display="flex"
      >
        <Sidebar />
        {wrapInMainScreen ? (
          <MainScreen
            style={{
              background,
              paddingTop: isPortfolio ? 0 : isLarge ? undefined : "4rem",
              padding: isPortfolio ? 0 : undefined,
            }}
          >
            {props.children}
          </MainScreen>
        ) : (
          props.children
        )}
      </Box>
    </PageTemplateContainer>
  ); // do <NavBarSideBarPageTemplate> <Sidebar /> ... </NavBarSideBarPageTemplate>
}

const PageBanner = () => {
  const dispatch = useDispatch();
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);
  const _setHasBanner = compose(dispatch, setHasBanner);

  useEffect(() => {
    if (isGlobalRuleMode) _setHasBanner(true);
  }, [_setHasBanner]);

  if (isGlobalRuleMode) return <GlobalRuleBanner />;

  return <FreeBanner />;
};

function NoNavBarPageTemplate(props: BoxProps) {
  return (
    <PageTemplateContainer {...props}>
      {/* this isn't used atm bc email verification is only done on the settings page. may change tho so adding here for now */}
      <VerifyEmailModal />
      <Content>{props.children}</Content>
    </PageTemplateContainer>
  );
}

export {
  NavBarPageTemplate,
  NoNavBarPageTemplate,
  SideBarPageTemplate,
  PageTemplateContainer,
  Content,
};
