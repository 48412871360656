import { Box, Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useClientById, useMyToast } from "src/hooks";
import { MutationFreeSubscriptionArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { colors } from "src/theme";
import { useDispatch } from "react-redux";
import { setShowConfetti } from "src/redux/reducers/globalState";
import { trackEvent } from "src/utils/analytics";
import { useTheme } from "src/hooks/useTheme";
import { motion } from "framer-motion";

type Props = InjectedProps & {
  title: string;
  onContinue: () => void;
};

function _FreeTaxModal({
  handleHide,
  title,
  show: isVisible,
  onContinue,
}: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const theme = useTheme();

  const _copyLink = () => {
    if (!client) return;

    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message:
        "Copied sharable link to clipboard! You can paste it in discord/imessage/twitter or wherever you want.",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const onHide = () => {
    handleHide();
  };

  const _submit = () => {
    onContinue();
    handleHide();
  };

  return (
    <Modal
      title="You'll get Awaken for free, but first..."
      titleHeaderProps={{
        fontSize: 24,
        marginRight: 10,
        color: theme.header,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        // fade in motion dive
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 5, duration: 1 } }}
          exit={{ opacity: 0 }}
          style={{ width: "100%" }}
        >
          <Button
            width="100%"
            bg={colors.green50}
            color={colors.white}
            marginBottom="0.5rem"
            marginTop="1.5rem"
            _hover={{ bg: colors.green40 }}
            onClick={_submit}
          >
            Unlock free report{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-arrow-right"></i>
          </Button>
        </motion.div>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text color={theme.text} fontSize="md">
          Please share Awaken with at least one person.
<br /><br />
Awaken was built by two devs coding feverishly at coffee shops in
          between sips of espresso. But despite working around-the-clock, we
          were slapped with rejection.
          <br />
          <br />
          VCs told us we were "too late to market". Competitors signed up and
          copied us. And no wallet/exchange would promote us.
          <br />
          <br />
          But whatever. We grew to 10,000 strong anyways. And that's because of
          one thing: you. You shared us. And we need to you to keep sharing us.
          <br />
          <br />
          <ul>
            <li>
              - Have a friend who trades crypto?{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={_copyLink}
              >
                Share us.
              </span>
            </li>
            <li>
              - In a discord or telegram group?{" "}
              <span
                style={{
                  fontWeight: "bold",
                  // discord purple
                  color: "#7289da",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={_copyLink}
              >
                Share us.
              </span>
            </li>
            <li>
              - Doomscrolling on twitter?{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: theme.header,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={_copyLink}
              >
                Share us.
              </span>
            </li>
          </ul>
          <br />
          We're not going to enforce this (bc I hate when companies do that).
          But we need you to do it anyways. So a gentlemen's handshake
          agreement, please spread the word 🤝
        </Text>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const FreeTaxModal = connectModal({
  name: "FreeTaxModal",
})(_FreeTaxModal);
