import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Outlet,
  useNavigate,
  Navigate,
  useMatch,
  useParams,
} from "react-router-dom";
import { useAuth } from "./useAuth";

// screens
import Accounts from "src/views/client/Accounts";
import Transactions from "src/views/client/Transactions";
import Taxes from "src/views/client/TaxReports";
import { Login, Signup } from "src/views/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { signOut } from "firebase/auth";
import { auth } from "src/utils/firebase";
import Team from "src/views/client/Settings";
import Referrals from "src/views/client/Referrals";
import PortfolioLanding from "src/views/consumer/PortfolioLanding";
import Rules from "src/views/client/Rules";
import { CreateCPA, FilterForm, Results } from "src/views/Accountants";
import History from "src/views/consumer/History";
import Disclaimer from "src/views/consumer/Legal/Disclaimer";
import Terms from "src/views/consumer/Legal/Terms";
import Privacy from "src/views/consumer/Legal/Privacy";
// import Assets from "src/views/client/Assets";
import HatchfiRedirect from "src/views/Oauth/HatchfiRedirect";
import Jobs from "src/views/client/Jobs";
import { PageTemplateContainer, SideBarPageTemplate } from "src/components";
import { useApolloClient } from "@apollo/client";
import { AuthStatus } from "src/redux/types";
import { Box, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { DesktopOnly } from "src/views/client/DesktopOnly";
import { isMobile } from "react-device-detect";
import { Welcome } from "src/views/Authentication/Welcome";
import { NFTsProof } from "src/views/consumer/Proof";
import { ForgotPassword } from "src/views/Authentication/ForgotPassword";
import { RedirectToTxn } from "./RedirectToTxn";
import Analytics from "src/views/client/Analytics";
import VezgoRedirect from "src/views/Oauth/VezgoRedirect";
import Diffs from "src/views/consumer/Diffs";
import { useClientById, useMe } from "src/hooks";
import { useIntervalWithDependencies } from "src/hooks/common";
import Harvest from "src/views/client/Harvest";
import { Portfolio } from "src/views/client/Portfolio";
import Dashboard from "src/views/Dashboard";
import AssetDetails from "src/views/client/AssetDetails";
import SpamAssets from "src/views/admin/SpamAssets";
import OAuthRedirect from "src/views/Oauth/OAuthRedirect";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import FriendTechTaxes from "src/views/consumer/Blog/FriendTechTaxes";
import { PhoneVerificationModal } from "src/components/modals/2FA/PhoneVerificationModal";
import { AddAccount } from "src/components/Accounts";
import AddAccountScreen from "src/views/client/AddAccount";
import TradingLanding from "src/views/consumer/TradingLanding";
import { getBaseLink } from "src/modules/ledger/transactions";
import PortfolioV2 from "src/views/client/PortfolioV2";
import { setTheme } from "src/redux/reducers/globalState";
import { useTheme } from "src/hooks/useTheme";
import { TaxProfessionals } from "src/views/client/TaxReports/TaxProfessionals/TaxProfessionals";
import Bookkeeping from "src/views/client/Bookkeeping";
import QuoteDetails from "src/views/client/QuoteDetails";
import News from "src/views/client/News";
import QuoteRequests from "src/views/client/QuoteRequests";
import TaxGPT from "src/views/client/TaxGPT";

const AdminRoutes = () => (
  <Routes>
    <Route path="spam-assets" element={<SpamAssets />} />
  </Routes>
);

const ClientRoutes = () => {
  const location = useLocation();
  // remove the first /
  // ex. /clients/123 => clients/123
  const parts = location.pathname.slice(1).split("/");

  // has to be client path and the last element has to empty or dashboard
  const isAllowed =
    location.pathname.includes("/clients") &&
    (parts[2] === undefined || parts[2] === "accounts");

  // just removing for now. I want to be able to use on phone even if it is crappy. the main flow of adding first account
  // is mobile friendly, rest isn't yet
  // if (isMobile && !isAllowed) {
  //   return <DesktopOnly />;
  // }
  const dispatch = useDispatch();

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  return (
    <Routes>
      <Route path=":clientId" element={<DashboardContainer />}>
        <Route path="portfolio">
          <Route path="" element={<PortfolioV2 />} />
          <Route path=":assetKey" element={<AssetDetails />} />
        </Route>
        <Route path="" element={<PortfolioV2 />} />
        <Route path="accounts">
          <Route path="" element={<Accounts />} />
          <Route path="new" element={<AddAccountScreen />} />
        </Route>
        <Route path="quotes">
          <Route path="" element={<Bookkeeping />} />
          <Route path="requests" element={<QuoteRequests />} />
          <Route path=":quoteId" element={<QuoteDetails />} />
        </Route>

        <Route path="news">
          <Route path="" element={<News />} />
        </Route>
        {/* redirect the dashboard route to portfolio */}
        <Route path="dashboard" element={<RedirectToPortfolio />} />
        <Route path="harvest">
          <Route path="" element={<Harvest />} />
          <Route path=":assetKey" element={<AssetDetails />} />
        </Route>
        <Route path="jobs">
          <Route path="" element={<Jobs />} />
        </Route>
        <Route path="transactions">
          <Route path="" element={<Transactions />} />
        </Route>
        <Route path="analytics" element={<Analytics />} />
        <Route path="rules">
          <Route path="" element={<Rules />} />
        </Route>
        <Route path="taxgpt">
          <Route path="" element={<TaxGPT />} />
        </Route>
        <Route path="taxes">
          <Route path="" element={<Taxes />} />
          <Route path="professionals" element={<TaxProfessionals />} />
        </Route>
        <Route path="team" element={<Team />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="history" element={<History />} />
        <Route
          path="diffs/:oldSummaryId/:newSummaryId/:year"
          element={<Diffs />}
        />
      </Route>
    </Routes>
  );
};

const RedirectToPortfolio = () => {
  const { clientId } = useParams(); // Capture the dynamic segment
  return <Navigate to={`/clients/${clientId}/portfolio`} replace />;
};

const DashboardContainer = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { checkToSyncNewTransactionsForClient } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  // check to sync new txns every 10 seconds. the backend will prevent rate limiting. this is kinda arbitrary and may end up
  // getting called multiple times. after the backend sets the last synced at there is a 5 minute buffer before can sync again through the UI
  // so this is just the code that checks to call that backend function
  useIntervalWithDependencies(
    () => checkToSyncNewTransactionsForClient(),
    5 * 1000,
    [checkToSyncNewTransactionsForClient]
  );

  return (
    <SideBarPageTemplate>
      <Outlet />
    </SideBarPageTemplate>
  );
};

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Dashboard />} />
    </Routes>
  );
};

const ProtectedRoute = ({
  authStatus,
  children,
}: {
  authStatus: AuthStatus;
  children: JSX.Element;
}) => {
  const theme = useTheme();

  if (authStatus === "LOADING" || authStatus === "NOT_LOADED") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background,
        }}
      >
        <Spinner color={theme.header} />
      </div>
    );
  }

  if (authStatus === "NOT_LOGGED_IN") {
    const path = window.location.pathname;
    // add the search params if there are any as well
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchString = params.toString();
    return (
      <Navigate
        to={`/login?redirect=${path}${searchString ? "&" + searchString : ""}`}
        replace
      />
    );
  }

  return children;
};

export const MainRoutes = () => {
  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);
  const authStatus = useSelector(getUserAuthStatus);

  const theme = useTheme();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isWhiteLabeled = isWhiteLabeledDomain();

  const dispatch = useDispatch();

  useEffect(() => {
    // if dark, set the html to have the theme.background color
    document.documentElement.style.backgroundColor = theme.background;
  }, [theme.background]);

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  // return <MaintenanceOverride />;

  return (
    <Routes>
      <Route path="" element={<TradingLanding />} />
      {/* <Route path="portfolio" element={<PortfolioLanding />} /> */}
      <Route path="landing" element={<TradingLanding />} />
      {/* <Route path="integrations" element={<Integrations />} /> */}
      <Route path="signup" element={<Signup />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="welcome" element={<Welcome />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="legal">
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms-of-service" element={<Terms />} />
      </Route>
      <Route path="blog">
        <Route path="friend-tech-taxes" element={<FriendTechTaxes />} />
      </Route>
      <Route path="ref">
        <Route path=":refCode" element={<RedirectReferral />} />
      </Route>
      {/* <Route path="proof">
        <Route path="nfts" element={<NFTsProof />} />
      </Route> */}
      <Route path="web3cpas">
        <Route path="/web3cpas/createCPA" element={<CreateCPA />} />
        <Route path=":formId" element={<Results />} />
        <Route path="" element={<FilterForm />} />
      </Route>
      <Route
        path="dashboard/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <DashboardRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <AdminRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="clients/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <ClientRoutes />
          </ProtectedRoute>
        }
      />
      <Route path="oauth">
        <Route path="hatchfi" element={<HatchfiRedirect />} />
        <Route path="vezgo" element={<VezgoRedirect />} />
        <Route
          path=":provider"
          element={
            <ProtectedRoute authStatus={authStatus}>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route path="redirect" element={<OAuthRedirect />} />
        </Route>
      </Route>
      <Route path="/:txnHash" element={<RedirectToTxn />}></Route>
    </Routes>
  );
};

const RedirectReferral = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { refCode } = useParams();

  useEffect(() => {
    navigate("/?ref=" + refCode);
  }, []);

  return null;
};

const Logout = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  useEffect(() => {
    signOut(auth)
      .then(() => apolloClient.cache.reset())
      .then(() => navigate("/login"));
  }, []);

  return null;
};

export const Navigation = () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);

const MaintenanceOverride = () => {
  return (
    <PageTemplateContainer h="100vh">
      <Box
        display="flex"
        flexDir={"column"}
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text maxWidth="30rem" w="96%" textAlign={"center"} marginBottom="1rem">
          Awaken is undergoing scheduled maintenance between 10-11pm PST
          tonight. All your data is safe. Thank you for understanding ❤️
          {/* We can email you a link to open */}
          {/* the app on your computer. 👇 */}
        </Text>
        {/* <Input
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Button variant="primary">Send email</Button> */}
      </Box>
    </PageTemplateContainer>
  );
};
