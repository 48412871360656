import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AccountProviderEnum,
  AssetTypeEnum,
  CurrencyCodeEnum,
  Mutation,
  MutationCreateTransferArgs,
  MutationUpdateTransferArgs,
  Query,
} from "src/api/generated/types";
import { ActiveTransactionContext } from "src/context";
import { colors } from "src/theme";
import { Button, Copy, Input, Option, Select } from "src/components/styled";
import { Touchable } from "src/components/Touchable";
import FocusLock from "react-focus-lock";
import { debounce, isEqual, select } from "radash";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api, BaseTransactionFullFields } from "src/api";
import {
  D,
  fromDollars,
  getCurrencySymbol,
  isLoadingGQL,
} from "src/utils/helpers";
import {
  BaseAccountFields,
  BaseAssetFields,
  BaseClientFields,
  BaseFullTransactionFields,
  BaseTransferFields,
} from "src/api/fragments";
import { useClientById, useMyToast } from "src/hooks";
import { useParams } from "react-router-dom";
import { AssetIcon } from "src/components/styled/Assets";
import { Maybe, hasValue } from "src/core";
import { DateTime } from "luxon";
import { isNil } from "lodash/fp";
import { isEmpty, noop, truncate } from "lodash";
import numbro from "numbro";
import { isInternalTransfer } from "src/utils/labels";
import { Currency } from "dinero.js";
import { useTheme } from "src/hooks/useTheme";
import BigNumber from "bignumber.js";
import { InjectedProps, connectModal, show } from "redux-modal";
import { TransferWithExtraInfo } from "../TxnDetailModal/Transfers/TransferForm/utils";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { Modal } from "src/components/Modal";
import moment from "moment";
import { useDispatch } from "react-redux";

type AcctOption = Option & { account: BaseAccountFields };

const fiatSchema = yup
  .number()
  .transform((_, v) => parseFloat(v.replace(/,/g, "")))
  .min(0);

export type TransferModalProps = InjectedProps & {
  transfer: TransferWithExtraInfo;
  field: "snapshot" | "basis";
  startingFiatValue: number;
  mode: "creating" | "editing";
  transaction: BaseFullTransactionFields;
};

const _TransferModal = ({
  transfer,
  field,
  startingFiatValue,
  handleHide,
  show: isVisible,
  mode,
  transaction,
}: TransferModalProps) => {
  const toast = useMyToast();

  const { clientId } = useParams();

  const { client, accounts } = useClientById(clientId || "", {
    onlyFetchClient: false,
    skipFetchAssetsOnLoad: true,
    accountFetchPolicy: "cache-first",
    clientFetchPolicy: "cache-first",
  });

  const currency = (transaction?.fiatCurrency ||
    CurrencyCodeEnum.Usd) as CurrencyCodeEnum;

  const [updateTransfer, { loading: loadingUpdate }] = useMutation<
    Pick<Mutation, "updateTransfer">,
    MutationUpdateTransferArgs
  >(api.transactions.updateTransfer);

  const [createTransfer, { loading: loadingCreate }] = useMutation<
    Pick<Mutation, "createTransfer">,
    MutationCreateTransferArgs
  >(api.transactions.createTransfer);

  const [getPricing, { data, networkStatus }] = useLazyQuery<
    Pick<Query, "getPriceForDate">
  >(api.pricing.getPriceForDate, { notifyOnNetworkStatusChange: true });

  const dispatch = useDispatch();

  const debouncedGetPrice = useRef(
    debounce({ delay: 250 }, async (assetId: string, amount: number) => {
      await getPricing({
        variables: {
          assetId,
          amount,
          date: transaction?.createdAt,
          currency: client?.currency || CurrencyCodeEnum.Usd,
        },
      });
    })
  );

  const [fiatAmount, setFiatAmount] = useState<number | string>(
    D(startingFiatValue || 0, currency).toFormat("0.00") // round to dollars
  );
  const [amount, setAmount] = useState<number | string>(transfer?.value);
  const [assetId, setAssetId] = useState<string>(transfer?.assetId || "");
  const [toAccountId, setToAccountId] = useState<string | null>(
    transfer ? transfer.toAccountId || null : null
  );

  const [fromAccountId, setFromAccountId] = useState<string | null>(
    transfer
      ? transfer.fromAccountId || null
      : transaction?.sourceAccountId || null
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mode === "editing") return;
    if (!assetId) return;
    if (!amount) return;
    debouncedGetPrice.current(assetId, parseFloat(amount.toString()));
  }, [debouncedGetPrice.current, assetId, amount]);

  useEffect(() => {
    if (mode === "editing") return;
    if (!assetId) return;
    if (!amount) return;

    if (!data?.getPriceForDate) return;
    if (!data?.getPriceForDate?.totalPrice) return;
    if (!data?.getPriceForDate?.hasPriceForDate) return;

    setFiatAmount(
      D(data?.getPriceForDate?.totalPrice || 0, currency).toFormat("0.00")
    );
  }, [data?.getPriceForDate?.totalPrice, amount, assetId]);

  const accountOptions = useMemo(
    (): AcctOption[] =>
      accounts
        .filter((a) => a.provider === transaction?.provider)
        .map((a) => ({
          account: a,
          value: a.id,
          name: a.description,
          label: a.description,
        })),
    [JSON.stringify(accounts, null)]
  );

  const toAccount = useMemo(() => {
    return accountOptions.find((a) => a.account.id === toAccountId);
  }, [toAccountId, accountOptions]);

  const fromAccount = useMemo(() => {
    return accountOptions.find((a) => a.account.id === fromAccountId);
  }, [fromAccountId, accountOptions]);

  const _onSaveSnapshotValue = async () => {
    try {
      if (!transaction) {
        return;
      }

      const fiatValue = await fiatSchema.validate(fiatAmount);

      if (isNil(fiatValue)) {
        return;
        startingFiatValue;
      }

      const fiatValueCents = D(fiatValue * 100, currency).getAmount();

      // if it is an internal or none transfer AND the whole txn isn't manually
      // labeled as internal -> don't allow editing
      // if it is labeled as internal, we want to let them edit the values. there were cases where
      // NFTs were NEVER staked but they are unstaked. and a user might want to override their values
      // ex. 0xaf96313898fb17d5145d2f04f26000765cba841ab8445cdfb97581f385775f9f this txn he is unstaking NFTs he never received
      // but he needs to edit the values. the hack to edit is label as wallet transfer and then manually edit/override the values
      const isInvalidTransferType =
        (transfer?.transferType === "internal" ||
          transfer?.transferType === "none") &&
        !isInternalTransfer(transaction?.labelUsed || null);

      // if (isInvalidTransferType && !transfer.isCreatedByUser) {
      //   toast.show({
      //     message: "Sorry, you cannot edit the fiat value of this transfer!",
      //     status: "error",
      //   });
      //   return;
      // }

      if (!toAccountId && !fromAccountId) {
        toast.show({
          message: "Please select a 'to' and/or 'from' account.",
          status: "error",
        });
        return;
      }

      // if (
      //   transfer.transferType === "sent" &&
      //   (received?.transfers?.length || 0) > 0
      // ) {
      //   // FIXME: hack for now
      //   if (transaction?.provider !== "solana") {
      //     toast.show({
      //       message:
      //         "Sorry, you cannot edit the fiat value of a transfer if there are received assets in the same transaction! Please edit the received assets instead.",
      //       status: "error",
      //     });
      //     return;
      //   }
      // }

      const newAmount = new BigNumber(amount);

      if (newAmount.isNaN()) {
        toast.show({
          message: "Invalid amount.",
          status: "error",
        });
        return;
      }

      // Note: don't send both the basis + fiat value at the same time. causes some weird cases. gotta fix this at some point FIXME:
      const transferUpdates: MutationUpdateTransferArgs = {
        transactionId: transaction.id,
        transferId: transfer.id,
        fiatValue: fiatValueCents,
        amount: newAmount.abs().toNumber(),
        assetId: assetId,
        fiatCurrency: transaction.fiatCurrency,
        // set original if it isn't already set
        originalFiatValue: isNil(transfer.originalFiatValue)
          ? // the starting value. it could be basis or snapshot, it is just so we have a record of what it was so they can revert
            startingFiatValue ?? 0
          : undefined,
        toAccountId: toAccountId || null,
        fromAccountId: fromAccountId || null,
      };

      await updateTransfer({
        variables: transferUpdates,
        fetchPolicy: "no-cache",
        refetchQueries: [api.transactions.retrieve, api.clients.transactions],
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "An error occurred.",
        status: "error",
      });
      return;
    }
  };

  const _onSaveBasisValue = async () => {
    try {
      if (!transaction) {
        return;
      }

      const fiatValue = await fiatSchema.validate(fiatAmount);

      if (isNil(fiatValue)) {
        return;
      }

      if (!toAccountId && !fromAccountId) {
        toast.show({
          message: "Please select a 'to' and/or 'from' account.",
          status: "error",
        });
        return;
      }

      const fiatValueCents = D(fiatValue * 100, currency).getAmount();

      if (transfer.transferType !== "sent") {
        toast.show({
          message: "Sorry, you cannot edit the basis value of this transfer!",
          status: "error",
        });
        return;
      }

      // We want to allow this because customer had full basis but value was $0.35... Should have been $300K.
      // if (!transfer.isMissingBasis) {
      //   toast.show({
      //     message:
      //       "Sorry, you cannot edit the basis value of this transfer because it is not missing. Go back to previous transactions to edit the basis.",
      //     status: "error",
      //   });
      //   return;
      // }

      if (!toAccountId && !fromAccountId) {
        toast.show({
          message: "Please select a 'to' and/or 'from' account.",
          status: "error",
        });
        return;
      }

      // Note: don't send both the basis + fiat value at the same time. causes some weird cases. gotta fix this at some point FIXME:
      const transferUpdates: MutationUpdateTransferArgs = {
        transactionId: transaction.id,
        transferId: transfer.id,
        fiatCurrency: transaction.fiatCurrency,
        basisFiatValue: fiatValueCents,
        toAccountId: toAccountId || null,
        fromAccountId: fromAccountId || null,
      };

      await updateTransfer({
        variables: transferUpdates,
        fetchPolicy: "no-cache",
        refetchQueries: [api.transactions.retrieve, api.clients.transactions],
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "An error occurred.",
        status: "error",
      });
      return;
    }
  };

  const _createTransfer = async () => {
    try {
      if (!transaction) {
        return;
      }

      const fiatValue = await fiatSchema.validate(fiatAmount);

      if (isNil(fiatValue)) {
        return;
      }

      const fiatValueCents = D(fiatValue * 100, currency).getAmount();

      const newAmount = new BigNumber(amount);

      if (newAmount.isNaN()) {
        toast.show({
          message: "Invalid amount.",
          status: "error",
        });
        return;
      }

      if (!assetId) {
        toast.show({
          message: "Please select an asset.",
          status: "error",
        });
        return;
      }

      if (!toAccountId && !fromAccountId) {
        toast.show({
          message: "Please select a 'to' and/or 'from' account.",
          status: "error",
        });
        return;
      }

      const variables: MutationCreateTransferArgs = {
        transactionId: transaction.id,
        fiatValue: fiatValueCents,
        amount: newAmount.abs().toNumber(),
        assetId: assetId,
        toAccountId: toAccountId || null,
        fromAccountId: fromAccountId || null,
      };

      await createTransfer({
        variables: variables,
        fetchPolicy: "no-cache",
        refetchQueries: [api.transactions.retrieve, api.clients.transactions],
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "An error occurred.",
        status: "error",
      });
      return;
    }
  };

  const _onSubmit = async () => {
    if (mode === "creating") {
      await _createTransfer();
      return;
    }

    if (field === "snapshot") {
      await _onSaveSnapshotValue();
    } else {
      await _onSaveBasisValue();
    }
  };

  const theme = useTheme();
  const isLarge = useIsLargeScreen();
  const loading = loadingUpdate || loadingCreate;

  const isValid = useMemo(
    () =>
      fiatSchema.isValidSync(fiatAmount) &&
      !isEqual(fiatAmount, transfer?.fiatAmountCents),
    [transfer?.fiatAmountCents, fiatAmount]
  );

  return (
    <Modal
      isVisible={isVisible}
      handleHide={handleHide}
      title={mode === "creating" ? "Add Transfer" : "Edit Transfer"}
      footerProps={{
        visibility: "visible",
        padding: "1rem",
        borderTop: "1px solid " + theme.border,
        background: theme.medBackground,
      }}
      trapFocus={false}
      titleHeaderProps={{
        marginTop: 0,
        padding: "1rem",
      }}
      headerProps={{
        padding: "0",
        marginTop: "0",
        borderBottom: "1px solid " + theme.border,
      }}
      bodyProps={{
        padding: 0,
        height: "100%",
        overflowY: "scroll",
      }}
      Footer={
        <Button
          disabled={!isValid}
          fontSize={14}
          variant="primary"
          w="100%"
          onClick={_onSubmit}
          minWidth="auto"
          isLoading={loading}
          _disabled={{
            opacity: 0.5,
            cursor: "not-allowed",
          }}
        >
          Save
        </Button>
      }
    >
      {/* <PopoverArrow
              bg={theme.background}
              bgColor={theme.background}
              boxShadow={
                // use the border
                `0 0 0 1px ${theme.border} !important`
              }
            /> */}
      <VStack
        alignItems="start"
        padding="1rem"
        borderRadius="10px"
        zIndex={100}
        marginBottom="0.5rem"
        position="relative"
      >
        {/* <StatusTag
                infoMessage={transferInfo.infoMessage}
                label={transferInfo.label.toUpperCase()}
                type={transferInfo.tagType}
              />  */}

        {/* <HStack>
              <Input
                label="Amount"
                register={register(`transfers.${index}.value`)}
              />
              <AssetSelector path={`transfers.${index}.assetId`} />
            </HStack> */}

        <FormLabel fontSize="sm" fontWeight="bold" color={theme.header}>
          Amount
        </FormLabel>

        <HStack style={{ width: "100%" }}>
          <Input
            flex={1}
            autoFocus={mode === "creating"}
            containerStyle={{ marginBottom: 0, flex: 1 }}
            value={amount}
            onChange={(e) => setAmount(e.target.value || "")}
          />

          <SelectAsset
            defaultAssetId={assetId}
            allowNFTs={true}
            provider={transaction?.provider}
            onSelect={(a) => setAssetId(a.asset.id)}
            width={175}
          />
        </HStack>

        <div style={{ marginTop: "0.5rem" }}>
          <Text
            style={{
              color: theme.text,
              fontSize: 14,
              // make sure you can select the text
              userSelect: "text",
            }}
          >
            Missing an asset?{" "}
            <span
              style={{
                fontWeight: "bold",
                color: colors.primary,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                dispatch(
                  show("AssetModal", {
                    asset: null,
                    provider: transaction?.provider,
                    mode: "creating",
                    onCreate: (asset: BaseAssetFields) => {
                      setAssetId(asset.id);
                    },
                  })
                );
              }}
            >
              Click to create.
            </span>
          </Text>
        </div>

        <br />

        <FormLabel fontSize="sm" fontWeight="bold" color={theme.header}>
          Fiat Amount ({currency})
        </FormLabel>

        <Input
          autoFocus={mode === "editing"}
          ref={inputRef}
          flex={1}
          containerStyle={{ marginBottom: 0, width: "100%" }}
          value={fiatAmount}
          onChange={(e) =>
            setFiatAmount((e.target.value || "").replace("$", ""))
          }
          iconLeft={
            <span
              style={{
                color: theme.text,
              }}
            >
              {getCurrencySymbol(currency)}
            </span>
          }
        />

        {data?.getPriceForDate?.hasPriceForDate && (
          <Text fontSize="sm" color={theme.text}>
            Price:{" "}
            {D(data?.getPriceForDate?.pricePerAsset || 0, currency).toFormat()}{" "}
            on {moment(transaction?.createdAt).format("M/D/YYYY")}
          </Text>
        )}

        {!isNil(transfer?.originalFiatValue) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Text
              fontSize="sm"
              marginBottom="0"
              color={theme.text}
              userSelect="text"
              pointerEvents="auto"
            >
              The original value was{" "}
              {/* Note: we don't show the currency here because we don't store it and it gets confusing. fine for now, it is just the currency number from the very beginning */}
              {D(transfer.originalFiatValue || 0).toFormat("0,0.00")}{" "}
            </Text>

            <Touchable
              style={{
                marginLeft: 5,
                marginBottom: 0,
                display: "inline-block",
              }}
              onClick={() => {
                setFiatAmount(
                  D(transfer.originalFiatValue || 0, currency)
                    .toFormat("0.00")
                    .toString()
                );
              }}
              label="Reset to original"
            />
          </div>
        )}

        <br />

        <HStack style={{ width: "100%", marginTop: "1rem" }}>
          <Flex flex={1}>
            <SelectAccount
              label="From account"
              selectedValue={fromAccount ?? null}
              options={accountOptions}
              onSelect={(a) => setFromAccountId(a?.account.id || "")}
            />
          </Flex>

          <i className="fa-sharp fa-arrow-right" />

          <Flex flex={1}>
            <SelectAccount
              label="To account"
              selectedValue={toAccount ?? null}
              options={accountOptions}
              onSelect={(a) => setToAccountId(a?.account.id || "")}
            />
          </Flex>
        </HStack>

        <div>
          <Text fontSize="xs" color={theme.text}>
            If you "sent" assets from one of your accounts, fill in the "from".
            If you "received" assets to one of your accounts, fill in the "to".
            You only need to set both the "to" and "from" if it is a transfer
            between your accounts.
          </Text>
        </div>

        {/* <TransferEntities transfer={transfer} index={index} /> */}

        {/* only render when open bc it makes a slow call to get assets */}
        {mode === "editing" && (
          <GetCryptoPricingAmount
            transfer={transfer}
            transaction={transaction}
            client={client}
          />
        )}
      </VStack>
    </Modal>
  );
};

const GetCryptoPricingAmount = ({
  transfer,
  transaction,
  client,
}: {
  transfer: TransferWithExtraInfo;
  transaction: BaseFullTransactionFields;
  client: BaseClientFields | null;
}) => {
  const [amount, setAmount] = useState(0);
  const [selectedAsset, setAssetOption] =
    useState<Maybe<BaseAssetFields>>(null);
  const [getPricing, { data, networkStatus, loading }] = useLazyQuery<
    Pick<Query, "getPriceForDate">
  >(api.pricing.getPriceForDate, { notifyOnNetworkStatusChange: true });
  const currency = (transaction?.fiatCurrency || "USD") as Currency;
  const isLoading = loading || isLoadingGQL(networkStatus);

  const debouncedGetPrice = useRef(
    debounce({ delay: 250 }, async (assetId: string, amount: number) => {
      await getPricing({
        variables: {
          assetId,
          amount,
          date: transaction?.createdAt,
          currency: client?.currency || CurrencyCodeEnum.Usd,
        },
      });
    })
  );

  useEffect(() => {
    if (!selectedAsset) return;
    if (!amount) return;
    debouncedGetPrice.current(selectedAsset.id, amount);
  }, [debouncedGetPrice.current, selectedAsset, amount]);

  const onSelectAsset = (a: AssetOption) => {
    setAssetOption(a.asset);
  };
  const theme = useTheme();

  const hasData = !!data?.getPriceForDate;
  const { hasPriceForDate, pricePerAsset, totalPrice } =
    data?.getPriceForDate || {};

  return (
    <>
      <Divider style={{ margin: "1rem 0" }} />
      <FormLabel color={theme.header} fontSize="sm" fontWeight="bold">
        Calculate Price
      </FormLabel>

      <Text fontSize="xs" color={theme.text}>
        This can help you calculate the price of the asset at the time of the
        transaction.
      </Text>

      <HStack
        width="100%"
        flex={1}
        marginTop="0"
        alignItems="center"
        justifyContent="center"
      >
        <Input
          flex={1}
          containerStyle={{ marginBottom: 0, flex: 1 }}
          value={amount}
          placeholder="200"
          type="number"
          onChange={(e) => setAmount(parseFloat(e.target.value))}
        />
        <SelectAsset
          onSelect={onSelectAsset}
          provider={transaction?.provider}
          width={200}
        />
      </HStack>
      {hasData && (
        <HStack>
          {isLoading ? (
            <HStack marginTop="1rem">
              <Spinner size="sm" />
              <Text fontSize="sm">Loading pricing...</Text>
            </HStack>
          ) : !hasPriceForDate ? (
            "Sorry, we do not have pricing on this date."
          ) : (
            <Box marginTop="1rem">
              <Text fontSize="sm" marginBottom="0.5rem" fontWeight="bold">
                Price on{" "}
                {DateTime.fromJSDate(
                  new Date(transaction?.createdAt || "")
                ).toFormat("MM/dd/yyyy")}{" "}
                <i
                  style={{ marginLeft: 5 }}
                  className="fa-sharp fa-calendar-alt"
                />
              </Text>
              <Text fontSize="md">
                Price: {D(pricePerAsset ?? null, currency).toFormat()} per{" "}
                {selectedAsset?.symbol}
              </Text>
              <HStack marginTop="0">
                <Text fontSize="md">
                  Total: {D(totalPrice ?? null, currency).toFormat()}
                </Text>
                <Copy
                  value={
                    D(totalPrice ?? null, currency)
                      .toUnit()
                      ?.toString() || ""
                  }
                />
              </HStack>
            </Box>
          )}
        </HStack>
      )}
    </>
  );
};

type AssetOption = {
  value: string;
  label: string;
  asset: BaseAssetFields;
};

const SelectAsset = ({
  onSelect,
  defaultAssetId,
  allowNFTs = false,
  provider,
  width,
}: {
  onSelect: (a: AssetOption) => void;
  defaultAssetId?: string;
  allowNFTs?: boolean;
  provider?: string;
  width?: number;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const { getAssets, assets } = useClientById(clientId);
  const [selectedAssetId, setSelectedAssetId] = useState<string | undefined>(
    defaultAssetId
  );

  useEffect(() => setSelectedAssetId(defaultAssetId), [defaultAssetId]);

  useEffect(() => {
    void getAssets();
  }, [clientId, getAssets]);

  const types = new Set(
    [
      AssetTypeEnum.FungibleToken,
      AssetTypeEnum.FiatCurrency,
      allowNFTs ? AssetTypeEnum.Nft : null,
    ].filter(hasValue)
  );

  const assetOptions: AssetOption[] = useMemo(
    () =>
      assets
        .filter((a) => types.has(a.type))
        // needs a coingecko ID, otherwise cannot get pricing anyways
        // .filter((a) => a.coinGeckoTokenId)
        .filter((a) => !a.isSpam)
        .filter((a) => a.provider === provider)
        .map((a) => ({
          value: a.id,
          label: a.symbol || a.name,
          asset: a,
        })),
    [assets]
  );

  const asset = assetOptions.find((a) => a.asset.id === selectedAssetId);
  const dispatch = useDispatch();

  const _onSelect = (val: AssetOption) => {
    onSelect(val);
  };

  const theme = useTheme();

  return (
    <Box width={width ?? 150}>
      <Select
        options={assetOptions}
        placeholder={"Crypto"}
        containerStyle={{ marginBottom: 0 }}
        selectProps={{
          isClearable: false,
          menuPortalTarget: document.body,
          value: asset,
          onChange: _onSelect as any, // FIXME:
          styles: {
            multiValue: (base, state) => ({
              ...base,
              minWidth: "inherit",
              color: theme.header,
              backgroundColor: theme.secondaryBackground,
            }),
            multiValueLabel: (base) => ({
              ...base,
              color: theme.header,
            }),
            control: (base) => ({
              ...base,
              backgroundColor: theme.background,
              borderColor: theme.border,
              border: "none",
            }),
            placeholder: (base) => ({
              ...base,
              color: theme.text,
            }),
            indicatorSeparator: (p) => ({ ...p, display: "none" }),
            singleValue: (p) => ({ ...p, color: theme.header }),
            container: (container) => ({
              ...container,
              backgroundColor: theme.background,
              borderRadius: "5px",
              border: "1px solid " + theme.border,
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            input: (base) => ({ ...base, color: theme.header }),
          },
          components: {
            Option: ({ innerRef, innerProps, label, data }) => {
              const theme = useTheme();
              return (
                <div>
                  <HStack
                    padding="0.5rem 0.5rem"
                    w="100%"
                    ref={innerRef}
                    {...innerProps}
                    _hover={{
                      bg: theme.secondaryBackground,
                      cursor: "pointer",
                    }}
                  >
                    <AssetIcon
                      style={{ marginRight: 0 }}
                      numCharacters={1}
                      asset={(data as any)?.asset}
                      size={18}
                    />
                    <Text
                      // hide if overflows flex
                      overflow="hidden"
                      flex={1}
                      fontSize="sm"
                      color={theme.text}
                    >
                      {label}
                    </Text>

                    <Touchable
                      iconName="fas fa-pen"
                      onClick={() => {
                        dispatch(
                          show("AssetModal", {
                            asset: (data as any).asset,
                            provider: (data as any).asset.provider,
                            mode: "editing",
                            onUpdate: (asset: any) => {
                              setSelectedAssetId(asset.id);
                            },
                          })
                        );
                      }}
                    />
                  </HStack>
                </div>
              );
            },
          },
        }}
        // isRequired
      />
    </Box>
  );
};

const SelectAccount = ({
  selectedValue,
  label,
  options,
  onSelect,
}: {
  label: string;
  selectedValue: AcctOption | null;
  options: AcctOption[];
  onSelect: (a: AcctOption) => void;
}) => {
  const theme = useTheme();

  return (
    <Select
      label={label}
      labelIconName="fa-sharp fa-wallet"
      options={options}
      containerStyle={{ width: "100%" }}
      placeholder=""
      selectProps={{
        isClearable: true,
        value: selectedValue,
        onChange: (a) => onSelect(a as any),
        styles: {
          multiValue: (base, state) => ({
            ...base,
            minWidth: "inherit",
            color: theme.header,
            backgroundColor: theme.secondaryBackground,
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: theme.header,
          }),
          control: (base) => ({
            ...base,
            backgroundColor: theme.background,
            borderColor: theme.border,
            border: "none",
          }),
          placeholder: (base) => ({
            ...base,
            color: theme.text,
          }),
          indicatorSeparator: (p) => ({ ...p, display: "none" }),
          singleValue: (p) => ({ ...p, color: theme.header }),
          container: (container) => ({
            ...container,
            backgroundColor: theme.background,
            borderRadius: "5px",
            border: "1px solid " + theme.border,
            // color: theme.header,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          input: (base) => ({ ...base, color: theme.header }),
        },
        components: {
          Option: ({ innerRef, innerProps, label, data }) => {
            const theme = useTheme();
            return (
              <Tooltip
                openDelay={500}
                label={(data as any).account?.description || ""}
              >
                <HStack
                  w="100%"
                  padding="0.75rem 0.5rem"
                  ref={innerRef}
                  {...innerProps}
                  _hover={{
                    bg: theme.secondaryBackground,
                    cursor: "pointer",
                  }}
                >
                  <Image
                    style={{ borderRadius: 3 }}
                    src={(data as any).account?.iconImageUrl}
                    w="1rem"
                    h="1rem"
                    margin="0 0.5rem"
                  />
                  <div style={{ fontSize: 14, color: theme.text }}>
                    {truncate((data as any).account?.description || "", {
                      length: 15,
                    })}
                  </div>
                </HStack>
              </Tooltip>
            );
          },
        },
      }}
    />
  );
};

export const TransferModal = connectModal({
  name: "TransferModal",
})(_TransferModal);
