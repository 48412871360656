import {
  Box,
  Center,
  Checkbox,
  HStack,
  Image,
  Td,
  Text,
  Tooltip,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Spinner,
} from "@chakra-ui/react";
import { debounce, throttle, truncate } from "lodash";
import { compose, isNil, uniq } from "lodash/fp";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import StatusTag, { StatusTagType } from "src/components/styled/StatusTag";
import { Maybe, hasValue } from "src/core";
import {
  ToastType,
  useMe,
  useMyToast,
  useTransactionById,
  useTransactionSearch,
} from "src/hooks";
import { colors, other } from "src/theme";
import { toLuxonUTC, toTitleCase } from "src/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { primaryGreen } from "src/theme/colors";
import { AwakenTooltip, CapGainsTag } from "src/components";
import {
  CurrencyCodeEnum,
  LabelOption,
  LedgerTransactionReviewStatusEnum,
  Query,
  RuleTypeEnum,
  TransactionTypeOption,
} from "src/api/generated/types";
import {
  INTEGRATIONS,
  PROVIDER_TO_LOGO_URL,
} from "src/components/modals/AccountModal/constants";
import Helpers from "src/utils/helpers";
import { IncomeTag } from "src/components/styled/Transaction/IncomeTag";
import { SelectTransactionsContext } from "./context";
import { ReviewStatusTag } from "src/components/Labels/ReviewStatusTag";
import BigNumber from "bignumber.js";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useDispatch, useSelector } from "react-redux";
import { getActiveHighlightedTransactionId } from "src/redux/reducers/active";
import { isLabeled, isReviewed } from "src/modules/ledger/transactions";
import { GraphQLError } from "graphql";
import { useTheme } from "src/hooks/useTheme";
import moment from "moment-timezone";
import { Touchable } from "src/components/Touchable";
import { useOnClickLabelSelect } from "src/components/Labels/useOnClickLabelSelect";
import { show } from "redux-modal";

type TransactionProps = {
  transaction: BaseSimpleTransactionFields;
  limitedView?: boolean; // show a few columns
  onClick?: () => void;
  timezone: string;
  handleContextMenu?: (e: any, id: string) => void;
};

const MAX_WIDTH = 35;

export function Transaction({
  transaction,
  limitedView,
  onClick,
  // UTC
  timezone = "UTC",
}: TransactionProps) {
  const { selectedTransactionIds, addOrRemoveTxnIds } = useContext(
    SelectTransactionsContext
  );
  const [_, { client: getTxnClient }] = useLazyQuery(api.transactions.retrieve);
  const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
    useMutation(api.transactions.update);
  const highlightedTxnId = useSelector(getActiveHighlightedTransactionId);

  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");

  const selected = searchTransactionId === transaction.id;
  const [isHighlighted, setHighlighted] = useState(
    highlightedTxnId === transaction.id
  );
  const toast = useMyToast();

  const { me } = useMe("cache-first");
  const isSuperUser = me?.isSuperuser || false;
  const { filters } = useTransactionSearch();
  const hasIncomeSort = filters.sortBy === "incomeSum";

  // right-click menu
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState<Maybe<string>>(null);

  const handleContextMenu = (e: any, id: string) => {
    // e.preventDefault();
    // setMenuPosition({ x: e.pageX, y: e.pageY });
    // setIsMenuOpen(id);
  };

  const handleClose = () => {
    setIsMenuOpen(null);
  };

  const _onClickTxn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    search.delete("transactionId"); // delete the current txn id
    setSearchParams(search);
    search.append("transactionId", transaction.id);
    setSearchParams(search);
    setHighlighted(true);
  };

  const title = transaction.title || "";

  const [accountLabelTruncated, accountLabel] = useMemo(
    () => [
      _getAccountLabel(transaction, 10),
      _getAccountLabel(transaction, 1_000),
    ], // arbitraryily large
    [transaction]
  );

  const needsRecalculate = transaction.needsReview?.needsRecalculate || false;

  const showMissingBasis = transaction.isMissingBasis;

  const _toggle = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    addOrRemoveTxnIds(transaction.id);
  };

  const isSelected = useMemo(
    () => selectedTransactionIds.includes(transaction.id),
    [selectedTransactionIds]
  );

  const markAsSpam = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const id = isMenuOpen;
    if (!id) return;
    if (!transaction) return;

    try {
      if (isLabeled(transaction))
        throw new Error("Transaction is already labeled");
      const variables = {
        updates: {
          notes: transaction.notes,
          title: transaction.title,
          label: "v1:spam",
          overrideLabel: true,
          globalRuleName: "",
        },
        transactionId: transaction.id,
        createDefaultRule: false,
      };

      const result = await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: `Successfully labeled transaction as spam${
          filters.includeSpam ? "" : " and hid it from view"
        }`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as GraphQLError).message,
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => setHighlighted(false), 5000);
    }
  }, [isHighlighted]);

  const {
    background,
    medBackground,
    secondaryBackground,
    header,
    text,
    border,
  } = useTheme();

  // if has a sum and that sum is greater than $100, it i red and should be looked at
  const isHighGainLoss =
    !isNil(transaction.capGainsSum) &&
    new BigNumber(transaction.capGainsSum || 0).isGreaterThan(10000);

  // const _preloadTxn = useCallback(() => {
  //   console.log("[pre-loading txn]");
  //   getTxnClient
  //     .query({
  //       fetchPolicy: "network-only",
  //       query: api.transactions.retrieve,
  //       variables: { transactionId: transaction.id },
  //     })
  //     .then(console.log);
  // }, [getTxnClient]);

  // const _preloadTxnDebounced = useCallback(throttle(_preloadTxn, 1000), [
  //   _preloadTxn,
  // ]);

  // if (transaction?.id === "fb292fd9-de54-4626-a847-80adc2453b8d")
  //   console.log(
  //     "NULL IS DIRTY: ",
  //     transaction?.isDirty,
  //     transaction?.isDirty === null
  //   );

  return (
    <Tr
      cursor="pointer"
      className="awaken__transaction-row"
      borderBottom={`1px solid ${border} !important`}
      _hover={{
        backgroundColor: medBackground,
      }}
      // onMouseOver={_preloadTxnDebounced}
      onClick={onClick || _onClickTxn}
      onContextMenu={
        handleContextMenu
          ? (e: any) => handleContextMenu(e, transaction.id)
          : undefined
      }
      bg={selected || isHighlighted ? secondaryBackground : background}
    >
      {/* {isMenuOpen !== null && (
        <Portal>
          <Menu isOpen={true} onClose={handleClose}>
            <MenuList
              position="absolute"
              left={`${menuPosition.x}px`}
              top={`${menuPosition.y}px`}
              style={{
                opacity: isMenuOpen ? 1 : 0,
                transition: "opacity 0.2s ease-in-out",
                position: "absolute",
                left: `${menuPosition.x}px`,
                top: `${menuPosition.y}px`,
              }}
            >
              <MenuItem
                onClick={(e) =>
                  isLoadingUpdateTransaction ? undefined : markAsSpam(e)
                }
              >
                {isLoadingUpdateTransaction ? (
                  <Spinner size="sm" margin="auto" />
                ) : (
                  <>
                    <i className="fa-sharp fa-trash" />
                    &nbsp;&nbsp;Mark as spam
                  </>
                )}
              </MenuItem>
            </MenuList>
          </Menu>
        </Portal>
      )} */}
      <Td borderColor={border} w="30px" onClick={(e) => e.stopPropagation()}>
        <div>
          <Checkbox
            borderColor={border}
            onChange={_toggle}
            isChecked={isSelected}
          />
        </div>
      </Td>
      <Td borderColor={border} paddingLeft="0rem !important" maxWidth="20rem">
        <HStack>
          <AwakenTooltip
            placement="top"
            message={
              INTEGRATIONS.find((i) => i.provider === transaction.provider)
                ?.name || "Provider"
            }
          >
            <Image
              src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
              marginRight="0.5rem"
              width="1.25rem"
              height="1.25rem"
              display="inline"
              style={{ borderRadius: 3 }}
            />
          </AwakenTooltip>
          <HStack alignItems="center" style={{ width: "100%" }}>
            <Box
              style={{
                overflow: "hidden",
              }}
            >
              <Tooltip openDelay={1000} placement="bottom-start" label={title}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    isTruncated
                    textAlign="left"
                    fontSize={14}
                    maxW="100%"
                    marginRight={15}
                    color={header}
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {title}
                  </Text>

                  {transaction.notes && (
                    <Tooltip
                      trigger="hover"
                      bg={colors.black}
                      placement="top-start"
                      borderRadius="0.25rem"
                      label={transaction.notes}
                      hasArrow
                    >
                      <i
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          color: text,
                        }}
                        className="fa-sharp fa-comment-alt-lines"
                      />
                    </Tooltip>
                  )}
                </div>
              </Tooltip>
              {transaction.description && (
                <Tooltip
                  openDelay={1000}
                  placement="bottom-start"
                  label={transaction.description || ""}
                >
                  <Box
                    isTruncated
                    marginTop="5px"
                    textAlign="left"
                    fontSize={14}
                    color={text}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    {transaction.description}
                  </Box>
                </Tooltip>
              )}
            </Box>

            {showMissingBasis ? (
              <StatusTag
                boxProps={{
                  style: {
                    marginLeft: 5,
                    display: "block",
                    padding: "3px 10px",
                  },
                }}
                infoMessage={`We are missing the purchase price for an asset in this transaction.${
                  isHighGainLoss
                    ? ""
                    : " The gain/loss is low for this transaction."
                }`}
                type={isHighGainLoss ? "error" : "none"}
                label=""
                iconStyle={{ fontSize: 14 }}
                iconName="fa-sharp fa-circle-exclamation"
              />
            ) : null}
          </HStack>
        </HStack>
      </Td>
      <Td borderColor={border}>
        <Center>
          <Tooltip
            openDelay={250}
            label={
              needsRecalculate
                ? "Run recalculate to update this"
                : transaction.processingType || "None"
            }
          >
            <div>
              <ReviewStatusTag
                isReviewed={isReviewed(transaction)}
                capPriority={transaction.capPriority}
                type={transaction.processingType || null}
                needsRecalculate={
                  transaction.needsReview?.needsRecalculate || false
                }
              />
            </div>
          </Tooltip>
          {/* )} */}
        </Center>
      </Td>
      <Td borderColor={border}>
        <Center>
          {hasIncomeSort ? (
            <IncomeTag
              transaction={transaction}
              isDirty={transaction.isDirty}
            />
          ) : transaction.isImporting ? (
            <Box />
          ) : (
            <CapGainsTag
              capPriority={transaction.capPriority ?? null}
              currency={transaction.fiatCurrency || CurrencyCodeEnum.Usd}
              capGainsSum={
                transaction.capGainsSumSigned ?? transaction.capGainsSum ?? null
              }
              isDirty={transaction.isDirty}
            />
          )}
        </Center>
      </Td>
      {!limitedView && (
        <Td borderColor={border}>
          <AwakenTooltip message={accountLabel}>
            <Center color={header}>{accountLabelTruncated}</Center>
          </AwakenTooltip>
        </Td>
      )}

      <Td
        borderColor={border}
        isNumeric
        style={{
          fontSize: 14,
        }}
        color={header}
      >
        {moment.tz(new Date(transaction.createdAt), timezone).format("h:mma")}
        <br />
        {moment
          .tz(new Date(transaction.createdAt), timezone)
          .format("MMM Do, yyyy")}
      </Td>

      <Td borderColor={border} isNumeric>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "none",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <FastLabel transaction={transaction} />

          {!!transaction?.blockExplorerUrl && (
            <AwakenTooltip message="View on block explorer">
              <div style={{ display: "flex" }}>
                <Touchable
                  iconName="fa-sharp fa-external-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    window.open(transaction.blockExplorerUrl || "", "_blank");
                  }}
                />
              </div>
            </AwakenTooltip>
          )}
        </div>
      </Td>
    </Tr>
  );
}

const _getDescription = (d: Maybe<string>) => {
  if (!d) return null;

  // split by | and then go through and if it starts with + make it green, if <> make it grey, if - make it red
  const parts = d.split("|");
  const partsWithColors = parts.map((_p) => {
    const p = _p.trim();
    //
    const [num, ...other] = p.split(" ");
    const asset = other.join(" ");
    let color: string = colors.black;

    if (p.startsWith("+")) {
      color = colors.positive;
    } else if (p.startsWith("<>")) {
      color = colors.primary;
    } else if (p.startsWith("-")) {
      color = colors.negative;
    }

    return (
      <Text
        key={p}
        style={{
          display: "inline-block",
          fontWeight: "medium",
          fontSize: 14,
        }}
      >
        <span style={{ display: "inline-block", color: color }}>{num}</span>{" "}
        {asset}
      </Text>
    );
  });

  return (
    <div>
      {partsWithColors.map((p, i) => (
        <span key={i}>
          {p}
          {i !== parts.length - 1 ? " | " : ""}
        </span>
      ))}
    </div>
  );
};

// ex. ETH -> coinbase etc... with the wallet label. will leave that to a future version tho
const _getAccountLabel = (txn: BaseSimpleTransactionFields, length: number) => {
  const accounts = uniq([
    ...txn.transfers
      .map((t) => [
        t.fromAccount
          ? toTitleCase(truncate(t.fromAccount.description, { length: length }))
          : null,
        t.toAccount
          ? toTitleCase(truncate(t.toAccount.description, { length: length }))
          : null,
      ])
      .flat()
      .filter(hasValue),
    ...txn.fees
      .map((t) =>
        t.payerAccount
          ? toTitleCase(
              truncate(t.payerAccount.description, { length: length })
            )
          : null
      )
      .filter(hasValue),
  ]);

  if (accounts.length >= 1) {
    return accounts.join(", ");
  }

  return "--";
};

const FastLabel = ({
  transaction,
}: {
  transaction: BaseSimpleTransactionFields;
}) => {
  const toast = useMyToast();
  const dispatch = useDispatch();

  const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
    useMutation(api.transactions.update);

  const [getLabelOptions, { data: _data }] = useLazyQuery<
    Pick<Query, "getTransactionTypeOptions">
  >(api.transactions.typeOptions);

  const onSelectOption = async (option: Maybe<TransactionTypeOption>) => {
    if (!option) return;

    try {
      const variables = {
        updates: {
          notes: transaction.notes,
          title: transaction.title,
          label: option.value,
          overrideLabel: true,
          globalRuleName: "",
        },
        transactionId: transaction.id,
        createDefaultRule: false,
      };

      await updateTransaction({
        variables,
        refetchQueries: [
          api.transactions.retrieve,
          api.clients.transactions,
          api.transactions.countTransactions,
          api.transactions.getNumTxnTypes, // refetch gains / losses txns
        ],
      });

      toast.show({
        message: `Successfully labeled transaction as ${option.label}`,
        status: "success",
      });
    } catch (err) {
      //
      toast.show({
        message:
          (err as GraphQLError).message ||
          "There was an error updating the transaction.",
        status: "error",
      });
    }
  };

  const _onClick = async (e: any) => {
    // get the labels

    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await getLabelOptions({
        variables: {
          transactionId: transaction.id,
        },
      });

      const labelOptions: LabelOption[] = (
        response?.data?.getTransactionTypeOptions?.labels || []
      )
        .filter(hasValue)
        // we don't want to create rules for internal transfer for all clients (i.e. global rule mode)
        .map((option: any) => ({ ...option, isDisabled: !option.applicable }));

      const selectedOption = labelOptions.find(
        (o) => o.value === transaction.labelUsed
      );

      dispatch(
        show("LabelsModal", {
          labelOptions,
          selectedOption,
          onSelectOption,
          loading: false,
          transaction,
        })
      );
    } catch (err) {
      toast.show({
        message:
          (err as any)?.message || "There was an error loading the labels",
        status: "error",
      });
    }
  };

  return (
    <AwakenTooltip message="Label transaction">
      <div style={{ display: "flex" }}>
        <Touchable
          iconName="fa-sharp fa-tag"
          onClick={_onClick}
          iconStyle={{
            // rotate 180 degrees
            transform: "rotate(90deg)",
          }}
        />
      </div>
    </AwakenTooltip>
  );
};
