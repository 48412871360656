import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createChart,
  ColorType,
  LineStyle,
  Time,
  Point,
  SingleValueData,
  UTCTimestamp,
  CrosshairMode,
  MouseEventHandler,
  MouseEventParams,
} from "lightweight-charts";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import CountUp from "react-countup";
import { Maybe, hasValue } from "src/core";
import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api } from "src/api";
import {
  AssetTypeEnum,
  ChartPoint,
  ChartPointBreakdown,
  GetPortfolioValueResponse,
  HistoricalBalanceIntervalEnum,
  MutationRefreshPortfolioArgs,
  PartialAsset,
  Query,
} from "src/api/generated/types";
import { DateTime } from "luxon";
import {
  chunk,
  debounce,
  isEmpty,
  isNil,
  keyBy,
  last,
  throttle,
  truncate,
} from "lodash";
import { D, formatNum } from "src/utils/helpers";
import { useClientById, useMe, useMyToast } from "src/hooks";
import * as moment from "moment-timezone";
import { Touchable } from "src/components/Touchable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AssetIcon } from "src/components/styled/Assets";
import numbro from "numbro";
import { Button, Info } from "src/components";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import qs from "query-string";
import { DEFAULT_TF, TIME_FRAMES, TimeFrame, getTimeFormat } from "./utils";
import { BaseAssetFields } from "src/api/fragments";
import { getAssetLinkFromId, isLabeled } from "src/modules/ledger/transactions";
import Assets from "./components/Assets";
import { PortfolioFeedbackModal } from "src/components/modals/PortfolioFeedbackModal";
import { useDispatch } from "react-redux";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import { useMediaQuery } from "@chakra-ui/react";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { Warning } from "src/components/Warning";
import { Summary } from "./components/Summary";
import DefiPositions from "./components/DefiPositions";
import NFTs from "./components/NFTs";
import { APP_STORE_LINK, CURRENT_TAX_YEAR } from "src/config";
import { trackEvent } from "src/utils/analytics";
import { useTheme } from "src/hooks/useTheme";

const CHART_HEIGHT = 325;
const REFETCH_DATA_INTERVAL = 60 * 1000;
const subject = "I'd like to do a feedback call about the portfolio";

type PartialPoint = Pick<ChartPoint, "timestamp" | "value"> & {
  breakdown: PointBreakdownWithAsset[];
};

type PointBreakdownWithAsset = Pick<
  ChartPointBreakdown,
  "assetId" | "value" | "price" | "amount"
> & {
  asset: PartialAsset;
};

type OverallGainLoss = {
  percent: string;
  color: string;
  amount: number;
  formattedAmount: string;
};

export const NetWorth = ({ clientId }: { clientId: string }) => {
  const { client, assets, getAssets } = useClientById(clientId);
  const { me } = useMe();
  const [search] = useSearchParams();
  const tf = search.get("tf") as unknown as Maybe<TimeFrame["type"]>;
  const defaultTf = TIME_FRAMES.find((d) => d.type === tf) || DEFAULT_TF;
  const theme = useTheme();

  const navigate = useNavigate();
  const [selectedValue, _setSelectedValue] = useState<Maybe<number>>(null);

  const [selectedPoint, _setSelectedPoint] =
    useState<Maybe<SingleValueData>>(null);
  const [timeFrame, setTimeFrame] = useState<Maybe<TimeFrame>>(defaultTf);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const timezone = client?.timezone ?? "UTC";
  const isSuperUser = me?.isSuperuser ?? false;
  const dispatch = useDispatch();

  const {
    data: chartData,
    loading: loadingChart,
    refetch: fetchChart,
    networkStatus,
  } = useQuery<{
    getChart: Query["getChart"];
  }>(api.portfolio.getChart, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    variables: {
      clientId,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    },
  });

  const { data: portfolioValueData, refetch: fetchPortfolioValue } = useQuery<{
    getPortfolioValue: Query["getPortfolioValue"];
  }>(api.portfolio.getPortfolioValue, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    variables: {
      clientId,
    },
  });

  const _onAddWallet = () => {
    dispatch(
      show("AccountModal", {
        location: "Portfolio",
      })
    );
  };

  const _setTimeFrame = (t: Maybe<TimeFrame>) => {
    setTimeFrame(t);
    _updateUrl({ tf: t?.type || undefined });
  };

  const assetById = useMemo(() => {
    const assetById = keyBy(assets, (a) => a.id);

    return {
      ...assetById,
    };
  }, [assets]);

  const fullPoints = useMemo(() => {
    const _points = chartData?.getChart?.points || [];
    if (!_points.length) return [];
    const currentValue = portfolioValueData?.getPortfolioValue;
    const currentValuePoint = currentValue
      ? {
          timestamp: currentValue.timestamp,
          value: currentValue.value,
          breakdown: currentValue?.breakdown,
        }
      : null;

    const points = [..._points, currentValuePoint]
      .filter(hasValue)
      .sort(_sortAsc);

    return points;
  }, [chartData?.getChart || "", portfolioValueData?.getPortfolioValue || ""]);

  const fullPointByTime = useMemo(
    () => keyBy(fullPoints, (p) => new Date(p.timestamp).toISOString()),
    [fullPoints]
  );

  const _updateUrl = (params: any) => {
    const newUrl = location.pathname + "?" + qs.stringify(params);

    navigate(newUrl, {
      replace: false, // please don't
    });
  };

  const throttledSelectedValue = useRef(throttle(_setSelectedValue, 50));
  const throttleSetSelectedPoint = useRef(throttle(_setSelectedPoint, 50));

  const points = useMemo<SingleValueData[]>(() => {
    const result = fullPoints.map((p): SingleValueData => {
      const isAfterNow = new Date(p.timestamp).getTime() > Date.now();
      return {
        // convert timestamp to seconds in the specific timezone. the dates are in UTC to start
        time: Math.floor(new Date(p.timestamp).getTime() / 1000) as Time,
        value: (isAfterNow ? undefined : p.value ?? 0) as number,
        // costBasis: !isNil(p.costBasis) ? p.costBasis / 100 : undefined,
      };
    });

    return result;
  }, [fullPoints]);

  const startingValue = useMemo(() => points[0]?.value ?? null, [points]);

  // current selected value is whatever point is selected
  const currentValue = useMemo((): Maybe<any> => {
    const currentValue = portfolioValueData?.getPortfolioValue;
    if (!currentValue) return null;
    return {
      time: Math.floor(
        new Date(currentValue.timestamp).getTime() / 1000
      ) as UTCTimestamp,
      value: currentValue.value,
      breakdown: currentValue.breakdown,
    };
  }, [portfolioValueData?.getPortfolioValue || ""]);

  const fullSelectedDataPoint = useMemo(() => {
    if (!selectedPoint) return null;
    const date = new Date((selectedPoint.time as any) * 1000).toISOString();
    // const backupPoint = fullPoints
    //   .slice()
    //   .reverse()
    //   .find((p) => !!p.value && p.value > 0);

    // get the date indexed in, otherwise return the most recent full point value above 0
    const pointValue = fullPointByTime[date] || currentValue;

    return pointValue;
  }, [fullPointByTime, fullPoints, selectedPoint, currentValue]);

  const lineColor = useMemo(() => {
    const first = points[0];
    const last = points[points.length - 1];
    if (isNil(first) || isNil(last)) return colors.positive;
    return first.value > last.value ? colors.negative : colors.positive;
  }, [points]);

  const overallGainLoss = useMemo((): Maybe<OverallGainLoss> => {
    if (isNil(startingValue) || isNil(selectedValue) || !selectedValue) {
      return null;
    }

    const percent =
      startingValue > 0
        ? (selectedValue - startingValue) / startingValue
        : null;

    return {
      percent:
        percent === null ? "N/A" : Math.abs(percent * 100).toFixed(2) + "%",
      color: isNil(percent) || percent >= 0 ? colors.positive : colors.negative,
      amount: selectedValue - startingValue,
      formattedAmount: D(
        Math.floor(Math.abs(selectedValue - startingValue) * 100),
        "USD"
      ).toFormat(),
    };
  }, [selectedValue, startingValue]);

  const _hardRefresh = async () => {
    try {
      await fetchChart({
        clientId,
        hardRefresh: true,
        interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
      });

      await fetchPortfolioValue({
        clientId,
        useCacheIfAvailable: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // chart + the current value of the portfolio
  useEffect(() => {
    fetchChart({
      clientId,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    }).then(() =>
      fetchPortfolioValue({
        clientId,
      })
    );
  }, [clientId, timeFrame]);

  useEffect(() => {
    throttledSelectedValue.current(currentValue?.value ?? null);
    throttleSetSelectedPoint.current(currentValue ?? null);
  }, [currentValue?.time || ""]);

  useEffect(() => {
    if (!selectedPoint) {
      throttledSelectedValue.current(currentValue?.value ?? null);
      throttleSetSelectedPoint.current(currentValue ?? null);
    }
  }, [selectedPoint?.time || ""]);

  useEffect(() => {
    getAssets();
  }, [clientId]);

  // useInterval(() => {
  //   // note: have to fetch new chart data as well bc otherwise have $0 points below the value
  //   // we need to do this before the most recent point tho so there isn't a weird gap where the line becomes $0
  //   _fetchPortfolioData();
  // }, REFETCH_DATA_INTERVAL);

  const isNeg = !isNil(currentValue) && currentValue.value < 0;
  const color = isNeg ? colors.negative : colors.positive;

  const toast = useMyToast();

  // show at most 6 rows
  const breakdown = useMemo(
    () => fullSelectedDataPoint?.breakdown || [],
    [fullSelectedDataPoint?.breakdown || ""]
  );

  const _onClickDownload = async () => {
    try {
      trackEvent("Portfolio Early Access Requested", {
        clientId,
        page: "portfolio_v2",
      });

      window.open(APP_STORE_LINK, "_blank");

      // await requestBeta();

      // toast.show({
      //   status: "success",
      //   message:
      //     "Successfully submitted early access request! You should have an email in your inbox soon.",
      // });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const isLarge = useIsLargeScreen();

  const [activeTab, _setActiveTab] = React.useState<"coins" | "defi" | "nfts">(
    "coins"
  );

  return (
    <div>
      <PortfolioFeedbackModal />

      {!isLarge && (
        // download the app
        <Button
          color={theme.background}
          style={{
            fontWeight: "700",
            marginTop: "0.25rem",
            width: "100%",
            borderRadius: 0,
            height: 40,
            marginBottom: "1rem",
          }}
          bg={theme.header + " !important"}
          borderRadius={"0 !important"}
          onClick={_onClickDownload}
        >
          <i className="fab fa-apple" style={{ marginRight: 10 }} /> Download
          App
        </Button>
      )}

      {/* {!isLarge && !showAccountsWarning && <PortfolioAccuracySurvey />} */}

      <HStack
        alignItems="flex-start"
        justifyContent="flex-start"
        marginTop="0.5rem"
        padding="0 0.5rem"
      >
        <VStack flex={1} alignItems="flex-start" maxW="100vw">
          <Summary />
          <PortfolioTabs activeTab={activeTab} setActiveTab={_setActiveTab} />

          {activeTab === "coins" && <Assets />}
          {activeTab === "defi" && <DefiPositions />}
          {activeTab === "nfts" && <NFTs />}
        </VStack>
      </HStack>
    </div>
  );
};

const PortfolioTabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: "coins" | "defi" | "nfts";
  setActiveTab: (tab: "coins" | "defi" | "nfts") => void;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [refreshPortfolioBalances] = useMutation(api.portfolio.refreshBalances);
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const { background, header, text, border } = useTheme();

  const _hardRefresh = async () => {
    if (!clientId) return;

    console.log(`[hard refreshing portfolio for ${clientId}]`);

    // don't allow using the balance cache
    const refreshVariables: MutationRefreshPortfolioArgs = {
      clientId: clientId,
      canUseBalanceCache: false,
      canUsePriceCache: true,
    };

    await refreshPortfolioBalances({
      variables: refreshVariables,
      refetchQueries: [
        api.portfolio.getPortfolioV2CoinsAndDefi,
        api.portfolio.getPortfolioV2CoinsAndDefi,
      ],
    });

    toast.show({
      message: "Refreshing portfolio... This may take a few minutes.",
      status: "success",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 15,
        width: "100%",
      }}
    >
      <HStack
        alignItems="center"
        justifyContent="flex-start"
        style={{
          flex: 1,
        }}
      >
        <Button
          variant="outline"
          size="sm"
          colorScheme="gray"
          style={{
            marginRight: 5,
            border: "1px solid " + text,
            color: activeTab === "coins" ? background : header,
            backgroundColor: activeTab === "coins" ? header : background,
          }}
          onClick={() => setActiveTab("coins")}
        >
          Coins <i className="fa-sharp fa-coins" style={{ marginLeft: 10 }} />
        </Button>

        <Button
          variant="outline"
          size="sm"
          colorScheme="gray"
          style={{
            marginRight: 5,
            border: "1px solid " + text,
            color: activeTab === "nfts" ? background : header,
            backgroundColor: activeTab === "nfts" ? header : background,
          }}
          onClick={() => setActiveTab("nfts")}
        >
          NFTs{" "}
          <i
            className="fa-sharp fa-hexagon-vertical-nft"
            style={{ marginLeft: 10 }}
          />
        </Button>

        <Button
          variant="outline"
          size="sm"
          colorScheme="gray"
          style={{
            marginRight: 5,
            border: "1px solid " + text,
            color: activeTab === "defi" ? background : header,
            backgroundColor: activeTab === "defi" ? header : background,
          }}
          onClick={() => setActiveTab("defi")}
        >
          DeFi{" "}
          <i
            className="fa-sharp fa-building-columns"
            style={{ marginLeft: 10 }}
          />
        </Button>
      </HStack>

      <Touchable
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={_hardRefresh}
        label={isLarge ? "Refresh Balances" : ""}
        iconName="fa-sharp fa-sync"
      />
    </div>
  );
};

const _AssetBreakdownInfo = ({
  breakdown: b,
  assetById,
}: {
  breakdown: ChartPointBreakdown;
  assetById: Record<string, BaseAssetFields>;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const asset = assetById[b.assetId];
  const provider = asset?.provider;

  if (!b.amount || !asset) {
    return null;
  }

  const isNFT = asset?.type === AssetTypeEnum.Nft;
  const assetName = isNFT
    ? asset?.name || asset?.symbol || "-"
    : asset?.symbol?.toUpperCase() || asset.name || "-";

  const assetLink = getAssetLinkFromId(clientId || "", asset.id);

  return (
    <div
      style={{
        verticalAlign: "top",
        padding: "5px 10px",
        flexShrink: 0,
      }}
    >
      <HStack style={{ position: "relative" }}>
        <AssetIcon
          textStyle={{ fontSize: 8 }}
          style={{ marginRight: "0.5rem" }}
          size={28}
          asset={asset}
        />

        {provider && PROVIDER_TO_LOGO_URL[provider || ""] ? (
          <Image
            position="absolute"
            w="1rem"
            h="1rem"
            borderRadius="100%"
            src={PROVIDER_TO_LOGO_URL[provider || ""] || ""}
            marginLeft="1rem !important"
            marginTop="1rem !important"
            border={"1px solid " + colors.gray80}
            bg={colors.white}
          />
        ) : null}

        <VStack alignItems="flex-start">
          <Text isTruncated fontSize="sm" fontWeight="500">
            {D(b.value || 0).toFormat()}{" "}
            <i
              // open font awesome icon
              className="fa-sharp fa-arrow-up-right-from-square"
              style={{
                marginLeft: 2,
                fontSize: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(assetLink, "_blank");
              }}
            />
            {/* <Text
              fontSize="xs"
              color={colors.gray30}
              style={{ display: "inline-block" }}
            >
              ({numbro(b.amount).format("0,0.[000]")})
            </Text> */}
            {/* {truncate(assetName || "", {
              length: 6,
              separator: "..",
            })} */}
          </Text>
          <Text
            isTruncated
            marginTop="0 !important"
            fontSize="xs"
            fontWeight="500"
          >
            {truncate(assetName || "", { length: 20 })} |{" "}
            {formatNum(b.amount, false, "0.[0000]")?.toLowerCase()}
            {/*  x{" "}
            {D(Math.floor(b.price || 0)).toFormat()} */}
          </Text>
        </VStack>
      </HStack>
    </div>
  );
};

const AssetBreakdownInfo = React.memo(_AssetBreakdownInfo);

const _TimeFrameOption = ({
  isActive,
  timeFrame,
  setActiveTimeFrame,
  activeColor,
}: {
  isActive?: boolean;
  timeFrame: TimeFrame;
  activeColor: string;
  setActiveTimeFrame: (timeFrame: TimeFrame) => void;
}) => {
  return (
    <div
      style={{
        backgroundColor: isActive ? activeColor : colors.gray90,
        borderRadius: 8,
        marginRight: 5,
        padding: "0.2rem 0.5rem",
        fontSize: 10,
        fontWeight: "bold",
        color: isActive ? "white" : colors.gray30,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveTimeFrame(timeFrame);
      }}
    >
      {timeFrame.label}
    </div>
  );
};

const TimeFrameOption = React.memo(_TimeFrameOption);

const _sortAsc = (
  a: Pick<ChartPoint, "timestamp">,
  b: Pick<ChartPoint, "timestamp">
) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();

export default NetWorth;
