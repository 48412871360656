import {
  Box,
  Checkbox,
  Container,
  FormLabel,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import {
  AccountProviderEnum,
  AccountTypeEnum,
  ImportTypeEnum,
  MutationCreateAccountArgs,
} from "src/api/generated/types";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { Input } from "src/components/styled/Form/Input";
import { BaseAccountFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { MyToast } from "src/components/MyToast";
import { Button, Info } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useParams } from "react-router-dom";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import EmojiPicker from "emoji-picker-react";
import { colors, other } from "src/theme";
import { PublicKey } from "@solana/web3.js";
import { any } from "lodash/fp";
import {
  ALLOWED_EVM_PROVIDERS,
  isValidEVMAddress,
} from "src/modules/ledger/accounts";
import { EVM_PROVIDERS } from "src/modules/ledger/accounts";
import {
  IntegrationAction,
  IntegrationProviderInfo,
} from "../AccountModal/types";
import { EmojiWalletName } from "./EmojiWalletName";
import { DEFAULT_EMOJIS } from "./defaultEmojis";
import { WalletHeaderImage } from "./WalletHeaderImage";
import { useTheme } from "src/hooks/useTheme";
import { useHandleVezgoLink } from "../AccountModal/AccountsWorkflow";
type Props = InjectedProps & {
  provider: string;
  onSuccess: (acct?: BaseAccountFields) => void;
  messageForWalletInput?: string;
  integration: IntegrationProviderInfo;
  label?: string;
  emoji?: string;
};

// same exact function on the backend
function isValidSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address);
    return true;
  } catch (e) {
    return false;
  }
}

function _WalletAccountModal({
  handleHide,
  onSuccess,
  show: isVisible,
  provider,
  integration,
  messageForWalletInput,
  label: _label,
  emoji: _emoji,
}: Props) {
  const isEVM = ALLOWED_EVM_PROVIDERS.has(provider?.toLowerCase() as any);
  const toast = useToast();
  const { clientId } = useParams<{ clientId: string }>();
  const myToast = useMyToast();
  const [label, setLabel] = useState(_label || "");
  const [address, setAddress] = useState("");
  const [skipRecalculate, setSkipRecalculate] = useState(false);
  const [includeStakingRewards, setIncludeStakingRewards] = useState(true);
  const [isLargeAccount, setIsLargeAccount] = useState(false);
  const [shouldUploadAllEVM, setShouldUploadAllEVM] = useState<boolean>(isEVM);
  const [createAccount, { loading }] = useMutation(api.accounts.create);

  const [isOpenEmoji, setOpenEmoji] = useState(false);
  const [defaultEmoji, defaultName] =
    DEFAULT_EMOJIS[Math.floor(Math.random() * DEFAULT_EMOJIS.length)];
  const [emoji, setEmoji] = useState(_emoji || defaultEmoji);

  const vezgoLink = useHandleVezgoLink(clientId);

  const _onAddWallet = useCallback(async () => {
    try {
      if (!label || !address) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={"Missing fields, please fill out below 👇"}
              status="error"
            />
          ),
        });
      }

      const trimmedAddress = address.replace(" ", ""); // remove spaces

      const isEVM = EVM_PROVIDERS.has(provider?.toLowerCase());

      if (isEVM) {
        const isValidEMV = isValidEVMAddress(trimmedAddress);

        if (!isValidEMV) {
          return toast({
            position: "top",
            render: () => (
              <MyToast
                message={"Invalid EVM address. Please check and try again."}
                status="error"
              />
            ),
          });
        }
      }

      if (
        provider?.toLowerCase() === AccountProviderEnum.Solana.toLowerCase() &&
        !isValidSolanaAddress(trimmedAddress)
      ) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={"Invalid Solana address. Please check and try again."}
              status="error"
            />
          ),
        });
      }

      if (
        provider?.toLowerCase() === AccountProviderEnum.Bitcoin.toLowerCase() &&
        // if xpub ypub zpub etc
        (trimmedAddress.includes("xpub") ||
          trimmedAddress.includes("ypub") ||
          trimmedAddress.includes("zpub"))
      ) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={
                "To add an xpub/ypub/zpub address, please click the 'Add xpub/ypub/zpub' link below the 'Add Account' button."
              }
              status="error"
            />
          ),
        });
      }

      const createAccountArgs: MutationCreateAccountArgs = {
        code: null,
        provider: provider,
        type: AccountTypeEnum.Wallet,
        redirectUrl: null,
        address: trimmedAddress,
        importType: ImportTypeEnum.Address,
        label: label ? `${emoji} ${label}` : null,
        clientId: clientId || null,
        skipRecalculate: skipRecalculate,
        isLargeAccount: isLargeAccount,
        includeStakingRewards,
        shouldUploadAllEVM: isEVM ? shouldUploadAllEVM : null,
      };

      await createAccount({
        variables: createAccountArgs,
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      myToast.show({
        message: `Importing transactions 🤖 This may take a while.`,
        status: "success",
      });

      handleHide();

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (err instanceof Error) {
        toast({
          position: "top",
          render: () => (
            <MyToast message={(err as Error).message} status="error" />
          ),
        });
      }
    }
  }, [
    address,
    emoji,
    label,
    clientId,
    skipRecalculate,
    isLargeAccount,
    shouldUploadAllEVM,
    includeStakingRewards,
  ]);

  const BestInfo = useMemo(() => {
    const text = <div />;

    // doing extraordinary support on AddAccountModal instead
    // if (provider === "ethereum") {
    //   text = (
    //     <Text marginTop="1rem" w="100%" textAlign={"left"} fontSize="sm">
    //       🎖 Best tax software for Ethereum{" "}
    //       <Info message="Awaken supports NFTs, staking, liquidity pools, internal transfers, bridging, and more on Ethereum." />
    //     </Text>
    //   );
    // }
    // else if (provider === "coinbase") {
    //   text = (
    //     <Text marginTop="1rem" w="100%" textAlign={"left"} fontSize="sm">
    //       🎖 Best tax software for Coinbase{" "}
    //       <Info message="Awaken supports exchange-to-wallet transactions, staking, and even advanced trades on Coinbase." />
    //     </Text>
    //   );
    // }

    // kraken: withdrawals, deposits, trades, and staking. dunno about margin there either

    return text;
  }, [provider]);

  const openLink = useCallback(async () => {
    if (integration.provider !== "bitcoin") return null;

    await vezgoLink(integration, {
      vezgoProvider: "bitcoin",
    });
  }, [integration]);

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      title={
        <>
          <WalletHeaderImage
            logoUrl={
              integration && integration.logoUrl // `integration` is undefined sometimes for some reason
            }
          />
          Add Account
        </>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      w={isLarge ? undefined : "95%"}
      overflow="visible"
      Footer={
        <Box w="100%">
          <Button
            width="100%"
            variant="primary"
            isLoading={loading}
            onClick={_onAddWallet}
          >
            Add Account
          </Button>
          {BestInfo}
          {/* <HStack marginTop="1.5rem">
            <Checkbox
              isChecked={skipRecalculate}
              onChange={(e) => {
                setSkipRecalculate(e.target.checked);
              }}
            />
            <Text fontSize="sm">
              Advanced: skip recalculation{" "}
              <Info message="If you are uploading a lot of wallets upfront, you may want to skip our tax recalculation. This will make the import faster. You can easily run our tax recalculation on the Tax Reports page at any time." />
            </Text>
          </HStack> */}
          {isEVM && (
            <HStack marginTop="1.5rem">
              <Checkbox
                isChecked={isLargeAccount}
                onChange={(e) => {
                  setIsLargeAccount(e.target.checked);
                }}
              />
              <Text color={theme.text} fontSize="sm">
                Large Wallet (over 10k transactions){" "}
                <Info message="These uploads take longer. Please check this for wallets with 10k+ transactions so we can scan the full blockchain for your history. We have a cap at 30,000 transactions; if you exceed this, contact support and we will help you!" />
              </Text>
            </HStack>
          )}
        </Box>
      }
      headerProps={{
        style: {
          padding: isLarge ? "2rem" : "1rem",
        },
      }}
      footerProps={{
        style: {
          padding: isLarge ? "2rem" : "1rem",
        },
      }}
      bodyProps={{
        style: {
          padding: isLarge ? "0 2rem" : "1rem",
        },
      }}
    >
      <Container padding="0px" marginTop="0px !important" paddingBottom="2rem">
        <EmojiWalletName
          label={label}
          setLabel={setLabel}
          emoji={emoji}
          setEmoji={setEmoji}
          isOpenEmoji={isOpenEmoji}
          setOpenEmoji={setOpenEmoji}
          defaultName={defaultName}
        />
        <Input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Wallet Address"
          subtitle={messageForWalletInput}
          infoMessage="Your wallet address. For ETH/Polygon, this starts with 0x."
          placeholder={
            provider === "ethereum"
              ? "0x1f9090aaE28b8a3dCeaDf281B0F12828e676c326"
              : ""
          }
        />

        {provider === "solana" && (
          <HStack marginTop="1.5rem">
            <Checkbox
              isChecked={includeStakingRewards}
              onChange={(e) => {
                setIncludeStakingRewards(e.target.checked);
              }}
            />
            <Text fontSize="sm" color={theme.text}>
              Include Solana staking reward transactions{" "}
              <Info
                message={`If you have staking accounts and you'd like us to pull in the rewards as transactions, which will default to being taxed as income when the staking account receives them, check this box. Otherwise, reward income is realized when you unstake, and not as they are paid to your staking accounts. Note: this will make the import take up to 5 minutes longer.`}
              />
            </Text>
          </HStack>
        )}

        {isEVM && (
          <HStack marginTop="1.5rem">
            <Checkbox
              isChecked={shouldUploadAllEVM}
              onChange={(e) => {
                setShouldUploadAllEVM(e.target.checked);
              }}
            />
            <Text color={theme.text} fontSize="sm">
              Upload all EVM wallets{" "}
              <Info
                message={`We'll import wallets for ${Array.from(
                  ALLOWED_EVM_PROVIDERS
                ).join(", ")} automatically.`}
              />
            </Text>
          </HStack>
        )}

        {integration?.provider === "bitcoin" && (
          <HStack marginTop="1.5rem">
            <Text
              onClick={openLink}
              color={colors.primary}
              fontWeight="bold"
              fontSize="sm"
              cursor="pointer"
              textDecoration="underline"
            >
              Click here to add xpub/ypub/zpub{" "}
              <i className="fas fa-arrow-right" />
            </Text>
          </HStack>
        )}
      </Container>
    </Modal>
  );
}

export const WalletAccountModal = connectModal({
  name: "WalletAccountModal",
})(_WalletAccountModal);
