import {
  BaseSubscriptionOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  Box,
  Link as ChakraLink,
  VStack,
  Text,
  HStack,
  Progress,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Spinner,
  Switch,
  Divider,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  ClientStatusEnum,
  Mutation,
  MutationFreeSubscriptionArgs,
  NumTxnsResponse,
  Query,
  QueryGetMyActiveSubscriptionArgs,
  QueryPacksForClientArgs,
  Subscription,
} from "src/api/generated/types";
import { colors, other } from "src/theme";
import { AddClientModal } from "../modals/AddClientModal";
import { FeedbackModal } from "../modals/FeedbackModal";
import { Touchable, TouchableProps } from "../Touchable";
import { ClientSelector } from "./ClientSelector";
import { NavigationLink } from "./NavigationLink";
import { CogsAnimation } from "../CogsAnimation";
import { compose, noop } from "lodash/fp";
import { isBrowser, isMobile } from "react-device-detect";
import {
  getGainsLossesLink,
  getPriorityLink,
} from "src/modules/ledger/transactions";
import numeral from "numeral";
import { config, CURRENT_TAX_YEAR } from "src/config";
import { omit } from "radash";
import { useClientById, useMe, useMyToast } from "src/hooks";
import StatusTag from "../styled/StatusTag";
import { CheckoutModal } from "../modals/CheckoutModal";
import {
  UseActiveSubscriptionResponse,
  useActiveSubscription,
} from "src/hooks/useActiveSubscription";
import { D } from "src/utils/helpers";
import { motion } from "framer-motion";
import { AwakenTooltip, Button, Info } from "../styled";
import {
  BaseClientFields,
  BaseReportFields,
  BaseUserFields,
} from "src/api/fragments";
import { EditClientModal } from "../modals/EditClientModal/EditClientModal";
import numbro from "numbro";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { getDrawerIsOpen, setDrawerIsOpen } from "src/redux/reducers/active";
import { Maybe } from "src/core";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { isFree } from "src/utils/free";
import { FreeSubscriptionModal } from "../modals/FreeSubscriptionModal";
import ReactConfetti from "react-confetti";
import {
  getShowConfetti,
  setShowConfetti,
} from "src/redux/reducers/globalState";
import moment from "moment";
import { ShareModal } from "../modals/ShareModal/ShareModal";
import { useInterval } from "src/hooks/common";
import { ClientNameModal } from "../modals/ClientNameModal/ClientNameModal";
import Lottie from "lottie-react";
import fireworksAnimation from "src/assets/animations/fireworks-3.json";
import { useTheme } from "src/hooks/useTheme";
import { PleaseShareModal } from "../modals/PleaseShareModal";
import { VIPQuoteRequestModal } from "../modals/VIPQuoteRequestModal";
import { maxBy, orderBy } from "lodash";
import { CompetitorCreditModal } from "../modals/CompetitorCreditModal";
import { FreeTaxModal } from "../modals/FreeTaxModal";

const WIDTH = "15rem";

export const Sidebar = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [showSidebar, setShowSidebar] = useState(true);
  const dispatch = useDispatch();
  const { me } = useMe();

  const highestPriorityLink = getGainsLossesLink({
    clientId: clientId || "",
    reviewed: false,
  });
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const activeSubscription = useActiveSubscription(clientId || "");

  const [getNumTxns, { data: numTxnsData, networkStatus: numTxnsStatus }] =
    useLazyQuery<{
      getNumTxns?: NumTxnsResponse;
    }>(api.transactions.countTransactions, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    });

  const { data } = useQuery<Pick<Query, "getClientBookkeepingQuotes">>(
    api.bookkeepingQuotes.listForClient,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  const hasBookkeeping = (data?.getClientBookkeepingQuotes?.length || 0) > 0;

  useEffect(() => {
    getNumTxns({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  const isSuperUser = me?.isSuperuser;
  const toast = useMyToast();
  const hasPortfolioEnabled = me?.hasPortfolioEnabled || false;
  const subject = "I'd like to do a feedback call about the portfolio";
  const isLarge = useIsLargeScreen();
  const isOpen = useSelector(getDrawerIsOpen);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const onlyShowAccounts = _onlyShowAccounts(me);
  const isWhiteLabel = isWhiteLabeledDomain();
  const {
    medBackground,
    header,
    secondaryBackground,
    text,
    border,
    theme,
    background,
  } = useTheme();

  const SidebarComponent = (
    <Box
      display="flex"
      flexDir={"column"}
      justifyContent="space-between"
      w={showSidebar ? (isLarge ? WIDTH : "100%") : "0rem"}
      h="100%"
      position="relative"
      style={{
        borderRight: `1px solid ${border}`,
        backgroundColor: background,
      }}
    >
      <AddClientModal />
      <EditClientModal />
      <FeedbackModal />
      <FreeSubscriptionModal />
      <ShareModal />
      <PleaseShareModal />
      <FreeTaxModal />

      {isLarge && (
        <Box
          w="1.4rem"
          h="3rem"
          bgColor={secondaryBackground}
          right="-1.4rem"
          top="0.4rem"
          position="absolute"
          border={`1px solid ${border}`}
          borderRightRadius={other.borderRadius}
          cursor="pointer"
          onClick={() => setShowSidebar(!showSidebar)}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <i
            className={`fa-sharp fa-chevron-${showSidebar ? "left" : "right"}`}
            style={{ color: colors.gray3 }}
          />
        </Box>
      )}

      <Box
        w="100%"
        h="100%"
        overflow={isLarge ? "hidden" : "scroll"}
        whiteSpace="nowrap"
      >
        <VStack h="100%" w="100%">
          <ClientSelector />

          {/* top content */}
          <Box flex={1} w="100%" margin={0} marginTop="0 !important">
            {onlyShowAccounts ? (
              <>
                <AccountsTab clientId={clientId || ""} />
                <NavigationLink
                  link={`/clients/${clientId}/transactions`}
                  label="Transactions"
                  iconName="fa-sharp fa-exchange"
                  notification={
                    client?.status === ClientStatusEnum.Importing
                      ? null
                      : numTxnsData?.getNumTxns?.high
                  }
                  // extraComponent={<CogsAnimation height="2.5rem" />}
                  notificationLink={highestPriorityLink}
                  notificationMessage={
                    <span style={{ color: text }}>
                      You have{" "}
                      <Link to={highestPriorityLink}>
                        <Text
                          fontSize="sm"
                          display="inline"
                          fontWeight="semibold"
                          color={colors.primary}
                          textDecoration="underline"
                        >
                          {" "}
                          {numTxnsData?.getNumTxns?.high} unlabeled transactions
                        </Text>
                      </Link>{" "}
                      with large gains/losses.
                    </span>
                  }
                />
              </>
            ) : (
              <>
                <PortfolioTab clientId={clientId || ""} />
                <AccountsTab clientId={clientId || ""} />
                {/* <PortfolioTab
                  clientId={clientId || ""}
                  hasPortfolioEnabled={hasPortfolioEnabled}
                /> */}

                <NavigationLink
                  link={`/clients/${clientId}/transactions`}
                  label="Transactions"
                  iconName="fa-sharp fa-exchange"
                  notification={
                    client?.status === ClientStatusEnum.Importing
                      ? null
                      : numTxnsData?.getNumTxns?.high
                  }
                  // extraComponent={<CogsAnimation height="2.5rem" />}
                  notificationLink={highestPriorityLink}
                  notificationMessage={
                    <span style={{ color: text }}>
                      You have{" "}
                      <Link to={highestPriorityLink}>
                        <Text
                          fontSize="sm"
                          display="inline"
                          fontWeight="semibold"
                          color={colors.primary}
                          textDecoration="underline"
                        >
                          {" "}
                          {numTxnsData?.getNumTxns?.high} unlabeled transactions
                        </Text>
                      </Link>{" "}
                      with large gains/losses.
                    </span>
                  }
                />

                {/* <NavigationLink
                  link={`/clients/${clientId}/harvest`}
                  // this way we show it as selected even at the root
                  links={[`/clients/${clientId}/harvest`]}
                  label={<>Tax Loss Harvesting</>}
                  iconName="fa-sharp fa-pumpkin"
                /> */}

                <NavigationLink
                  link={`/clients/${clientId}/taxes`}
                  label="Tax Reports"
                  iconName="fa-sharp fa-file-spreadsheet"
                />

                <NavigationLink
                  link={`/clients/${clientId}/taxgpt`}
                  label="TaxGPT"
                  iconName="fa-sharp fa-microchip-ai"
                />

                {/* <NavigationLink
                  link={`/clients/${clientId}/news`}
                  label="News"
                  iconName="fa-sharp fa-newspaper"
                  extraComponent={
                    <StatusTag
                      type="warning"
                      label="Soon"
                      iconName="fa-sharp fa-sparkles"
                    />
                  }
                /> */}

                <Divider
                  style={{
                    margin: "0.5rem 0",
                    borderColor: border,
                  }}
                />

                {hasBookkeeping && (
                  <NavigationLink
                    link={`/clients/${clientId}/quotes`}
                    label="Bookkeeping"
                    iconName="fa-sharp fa-abacus"
                  />
                )}

                <NavigationLink
                  link={`/clients/${clientId}/referrals`}
                  label="Referrals"
                  iconName="fa-sharp fa-share-alt"
                  extraComponent={<BeatingHeart />}
                />

                <NavigationLink
                  link={`/clients/${clientId}/team`}
                  label="Settings"
                  iconName="fa-sharp fa-gear"
                />

                <Divider
                  style={{
                    margin: "0.5rem 0",
                    borderColor: border,
                  }}
                />
              </>
            )}

            {(me?.role === "accountant" || me?.isSuperuser) && (
              <>
                {(me?.role === "accountant" || me?.isSuperuser) && (
                  <NavigationLink
                    link={`/clients/${clientId}/quotes/requests`}
                    label="Quotes"
                    iconName="fa-sharp fa-inbox"
                  />
                )}
              </>
            )}

            {!onlyShowAccounts && <DarkModeToggle />}
          </Box>
          {/* <ImproveAwaken /> */}

          {/* {client && <VIPSection client={client} />} */}

          {!onlyShowAccounts && (
            <VStack
              alignItems="center"
              marginTop="0 !important"
              w="100%"
              borderRadius={0}
              borderTop={`1px solid ${border}`}
              bg={medBackground}
            >
              <CurrentTransactions activeSubscription={activeSubscription} />
            </VStack>
          )}
          {!onlyShowAccounts && (
            <UnlimitedCXSection activeSubscription={activeSubscription} />
          )}
        </VStack>
      </Box>
    </Box>
  );

  if (isLarge) return SidebarComponent;

  return (
    <Drawer
      onClose={() => setDrawerOpen(false)}
      isOpen={isOpen}
      placement="right"
    >
      <DrawerOverlay />
      <DrawerContent>{SidebarComponent}</DrawerContent>
    </Drawer>
  );
};

const BeatingHeart = () => {
  return (
    <div
      style={{
        borderRadius: 10,
        padding: "0px 10px",
        backgroundColor: colors.pink50,
        animation: "beat 2s infinite",
        display: "inline-block",
      }}
    >
      <i
        className="fa-sharp fa-heart"
        style={{
          color: colors.white,
          transformOrigin: "center",
          fontSize: 14,
          animation: "beat-icon 1s infinite",
        }}
      />
    </div>
  );
};

const UnlimitedCXSection = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const dispatch = useDispatch();
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);

  // don't show unless they have a subscription for now
  if (!activeSubscription.subscription) {
    return null;
  }

  // if (activeSubscription.hasUnlimitedCX) {
  //   return (
  //     <Box
  //       style={{
  //         marginTop: 0,
  //         width: "100%",
  //         padding: "1.75rem 0.5rem 1.75rem 0.5rem",
  //         borderTop: `1px solid ${colors.gray80}`,
  //         backgroundColor: colors.primary,
  //       }}
  //     >
  //       <Text
  //         fontSize="sm"
  //         fontWeight="semibold"
  //         color={colors.white}
  //         whiteSpace="normal"
  //       >
  //         You have unlimited customer support{" "}
  //         <i
  //           className="fa-sharp fa-sparkles"
  //           style={{ color: colors.yellow50 }}
  //         />
  //       </Text>

  //       <SmallButton
  //         // help icon
  //         marginTop="1rem"
  //         icon={
  //           // animate this to pulse with motion.div
  //           <motion.i
  //             animate={{ scale: 1 }}
  //             transition={{
  //               repeat: Infinity,
  //               repeatType: "reverse",
  //               duration: 0.5,
  //             }}
  //             style={{
  //               top: 0,
  //               position: "relative",
  //               fontSize: 16,
  //               color: colors.black,
  //             }}
  //             className="fa-sharp fa-arrow-right"
  //           />
  //         }
  //         iconPosition="right"
  //         label={"Ask us a question"}
  //         style={{
  //           color: colors.black,
  //           marginBottom: 0,
  //           border: `1px solid ${colors.lightBlue80}`,
  //         }}
  //         bg={colors.white}
  //         border={undefined}
  //         _hover={{ bg: undefined }}
  //         iconStyle={{
  //           color: colors.primary,
  //         }}
  //         labelStyle={{
  //           textAlign: "center",
  //           width: "100%",
  //           flex: 1,
  //           color: colors.black,
  //         }}
  //       />
  //     </Box>
  //   );
  // }

  return null;

  // return (
  //   <Box
  //     style={{
  //       marginTop: 0,
  //       width: "100%",
  //       padding: "2rem 0.5rem 3rem 0.5rem",
  //       borderTop: `1px solid ${colors.gray80}`,
  //       backgroundColor: colors.primary,
  //     }}
  //   >
  //     <AwakenTooltip
  //       margin="0.5rem"
  //       message="Unlock unlimited customer support help. We'll answer any questions you have on how to use the software, what to label specific transactions, figure out problems with your CSVs, and anything else you need. You'll get responses from a real person (no AI bots)."
  //     >
  //       <Text
  //         fontSize="sm"
  //         fontWeight="semibold"
  //         color={colors.white}
  //         whiteSpace="normal"
  //       >
  //         Want to purchase customer support?
  //       </Text>
  //     </AwakenTooltip>
  //     <Box marginTop="1rem">
  //       <UnlimitedCX subscription={activeSubscription.subscription} />
  //     </Box>
  //   </Box>
  // );
};

const AccountsTab = ({ clientId }: { clientId: string }) => {
  if (!clientId) return null;
  return (
    <NavigationLink
      link={`/clients/${clientId}/accounts`}
      label="Accounts"
      iconName="fa-sharp fa-wallet"
      links={[
        `/clients/${clientId}/accounts`,
        // `/clients/${clientId}/dashboard`,
        // `/clients/${clientId}`,
      ]}
    />
  );
};

const PortfolioTab = ({ clientId }: { clientId: string }) => {
  const loc = useLocation();

  if (!clientId) return null;
  return (
    <NavigationLink
      link={`/clients/${clientId}/portfolio`}
      label="Portfolio"
      isActive={loc.pathname.includes("/portfolio/")}
      iconName="fa-sharp fa-chart-pie-alt"
      links={[
        `/clients/${clientId}/portfolio`,
        `/clients/${clientId}/dashboard`,
        `/clients/${clientId}`,
      ]}
      extraComponent={
        <StatusTag
          type="beta"
          label="Harvest"
          infoMessage="Beta: tax loss harvest before the end of the year to save on taxes."
          iconStyle={{ fontSize: 12 }}
          iconName="fa-sharp fa-carrot"
        />
      }
    />
  );
};

const ImproveAwaken = () => {
  return (
    <VStack
      alignItems="flex-start"
      marginTop="0 !important"
      w="100%"
      padding="1rem"
      marginBottom="0 !important"
      borderTop={`1px solid ${colors.gray2}`}
    >
      <Text whiteSpace="normal" fontSize="sm" paddingBottom="0.2rem">
        Help us improve Awaken 🙏
      </Text>
      <FeatureRequest />
      {/* <ContactUs /> */}
    </VStack>
  );
};

const _onlyShowAccounts = (user: Maybe<BaseUserFields>) => {
  if (!user) return false;

  const isAccountant = user.role === "accountant";
  const isSuperUser = user.isSuperuser;
  const isWhiteLabel = isWhiteLabeledDomain();

  // if it isn't whitelist or the below are true they can see everything
  if (!isWhiteLabel) return false;
  if (isAccountant) return false;
  if (isSuperUser) return false;

  return true;
};

const ChecklistItem = ({
  label,
  isCompleted,
  infoMessage,
  link,
}: {
  label: string;
  isCompleted: boolean;
  infoMessage?: string;
  link: string;
}) => {
  return (
    <Link to={link} color={colors.black}>
      <HStack>
        <i
          className={
            isCompleted ? "fa-regular fa-circle-check" : "fa-regular fa-circle"
          }
          style={{
            fontSize: 20,
            color: isCompleted ? colors.green50 : colors.gray60,
          }}
        />
        <Text
          _hover={{
            textDecoration: "underline",
            color: colors.black,
          }}
          style={{ color: colors.black, fontWeight: 500, fontSize: 14 }}
          cursor="pointer"
          fontSize="sm"
        >
          {label}{" "}
          {infoMessage && (
            <Info style={{ color: colors.gray40 }} message={infoMessage} />
          )}
        </Text>
      </HStack>
    </Link>
  );
};

const FeatureRequest = () => {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onClickContact = () => _showModal("FeedbackModal");

  return (
    <SmallButton
      iconName="fa-sharp fa-heart"
      label="Leave Feedback"
      onClick={_onClickContact}
    />
  );
};

const _getProgressBarClassName = (totalUsed: number, percent: number) => {
  if (totalUsed === 0) {
    return "awaken__progress_bar_blue";
  }
  if (percent > 100) {
    return "awaken__progress_bar_red";
  }
  return "awaken__progress_bar_blue";
};

const CurrentTransactions = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);

  // free code doesn't count -> hacky way to give users first txns
  const hasCode = !!client?.referredByCode;
  const hasCredit =
    !!client?.availableCreditCents && client?.availableCreditCents > 0;

  // FIXME: hack for removing paid bar
  // if (!hasCredit) {
  //   return null;
  // }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {hasCredit && (
        <AwakenTooltip
          maxWidth="15rem"
          message="Credit is applied automatically during checkout! Credit may expire 30 days after earning it, so make sure to use it."
        >
          <Box
            style={{
              padding: "0.6rem 0.3rem",
              margin: "0",
              marginBottom: 0,
              borderRadius: 0,
              width: "100%",
              // green gradient background
              background:
                "linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)",
              // borderTop: `1px solid ${colors.gray80}`,
              // borderBottom: `1px solid ${colors.gray80}`,
              color: colors.black,
              fontWeight: "bold",
              fontSize: 13,
              textAlign: "center",
            }}
          >
            {/* <motion.div
            style={{ display: "inline-block", marginRight: 2 }}
            animate={{ scale: 1 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            }}
          >
            <i style={{ fontSize: 16 }} className="fa-sharp fa-piggy-bank" />
          </motion.div>{" "} */}

            <span>
              {D(client?.availableCreditCents ?? 0, "USD").toFormat()} of credit{" "}
            </span>
          </Box>
        </AwakenTooltip>
      )}

      {/* <div style={{ marginBottom: "1rem", width: "100%", textAlign: "center" }}>
        <Steps />
      </div> */}

      <div
        style={{
          width: "100%",
          padding: "0.6rem",
          paddingBottom: "1.25rem",
        }}
      >
        <CurrentTaxPlan activeSubscription={activeSubscription} />
      </div>
    </div>
  );
};

const DarkModeToggle = () => {
  const { toggleDarkMode, theme } = useTheme();

  return (
    <div
      style={{
        marginTop: 25,
        padding: "0 15px 20px 15px",
        width: "100%",
      }}
    >
      <HStack>
        <i
          className="fa-sharp fa-moon"
          style={{ color: colors.gray40, fontSize: 20 }}
        />
        <Text
          style={{
            flex: 1,
            color: colors.gray40,
            fontWeight: 500,
            fontSize: 14,
          }}
          fontSize="sm"
        >
          Dark Mode
        </Text>

        <Switch
          onChange={(e) => toggleDarkMode()}
          isChecked={theme === "dark"}
        />
      </HStack>
    </div>
  );
};

const CurrentTaxPlan = ({
  activeSubscription,
}: {
  activeSubscription: UseActiveSubscriptionResponse;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const [free, setFree] = useState(isFree());

  const runConfetti = useSelector(getShowConfetti);

  const toast = useMyToast();

  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const {
    totalAvailable: _totalAvailable,
    totalUsed: _totalUsed,
    hasSubscription,
    subscription,
    loading,
    discountCalculation,
  } = activeSubscription;

  const totalAvailable = _totalAvailable ?? 0;
  const totalUsed = _totalUsed ?? 0;
  const percentFilled =
    totalAvailable > 0 ? (totalUsed / totalAvailable) * 100 : 100;
  const progressBarClassName = _getProgressBarClassName(
    totalUsed,
    percentFilled
  );
  const payable = discountCalculation?.numPayableTxns ?? null;

  // hide progress for free subscriptions bc not really relevant
  const hideProgress =
    (!hasSubscription && free) ||
    (hasSubscription && subscription?.isFreeSubscription);

  const { background, header, theme, secondaryBackground, ternaryBackground } =
    useTheme();

  const onSuccessFree = () => {
    _showModal("FreeSubscriptionModal");
    // toast.show({
    //   message: "You just unlocked free tax reports until March 1st!",
    //   status: "success",
    // });
  };

  // check every 5 minutes to see if still free
  useInterval(() => {
    setFree(isFree());
  }, 5 * 60 * 1000);

  const isInfinite = totalAvailable >= 1_000_000;

  if (loading) {
    return (
      <Box alignItems="center" display="flex" margin="2rem 0 2rem">
        <Spinner size="sm" style={{ marginRight: 10 }} />
        <Text fontSize="sm">Loading subscription...</Text>
      </Box>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      {free && !hasSubscription && (
        <Box
          style={{
            backgroundColor: colors.gray90,
            padding: 5,
            border: `1px solid ${colors.gray70}`,
            borderRadius: 5,
            margin: "15px 0",
          }}
        >
          <Text fontSize="sm">
            Awaken is{" "}
            <b
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                textDecoration: "underline",
              }}
            >
              free
            </b>{" "}
            until March 1st!
          </Text>
        </Box>
      )}
      {!hideProgress && (
        <HStack marginTop="1rem" marginBottom="1rem">
          <Progress
            width="100%"
            value={percentFilled}
            max={100}
            className={progressBarClassName}
            height={2}
            borderRadius={2}
            bg={ternaryBackground}
          />
          {hasSubscription && (
            <AwakenTooltip
              message={
                percentFilled < 100
                  ? "Your subscription covers your current number of transactions."
                  : "You have more transactions than your subscription covers so you need to upgrade."
              }
            >
              <i
                className={
                  percentFilled <= 100
                    ? "fa-sharp fa-check-circle"
                    : "fa-sharp fa-exclamation-circle"
                }
                style={{
                  color:
                    percentFilled <= 100 ? colors.primary : colors.yellow30,
                }}
              />
            </AwakenTooltip>
          )}
        </HStack>
      )}

      {hasSubscription ? (
        isInfinite ? (
          <Text
            color={header}
            marginTop="0.5rem"
            whiteSpace="normal"
            fontSize="sm"
          >
            You're subscribed to the unlimited transaction plan
          </Text>
        ) : (
          <Text
            color={header}
            marginTop="0.5rem"
            whiteSpace="normal"
            fontSize="sm"
          >
            Used {numbro(totalUsed || 0).format("0,0")} out of{" "}
            {isInfinite ? "∞" : numbro(totalAvailable || 0).format("0,0")}{" "}
            transaction limit for {CURRENT_TAX_YEAR} ({percentFilled.toFixed(0)}
            %)
          </Text>
        )
      ) : (
        <Text
          color={header}
          marginTop="0.5rem"
          whiteSpace="normal"
          fontSize="sm"
          w="100%"
        >
          You have {numbro(totalUsed || 0).format("0,0")} transactions in{" "}
          {CURRENT_TAX_YEAR}.
        </Text>
      )}

      <ReactConfetti
        style={{ zIndex: 100000 }}
        run={runConfetti}
        colors={[
          colors.yellow50,
          colors.orange50,
          colors.green50,
          colors.red50,
        ]}
        numberOfPieces={250}
        recycle={false}
      />

      <PurchaseButton
        onSuccessFree={onSuccessFree}
        totalAvailable={totalAvailable}
        hasSubscription={hasSubscription}
        subscription={subscription}
        free={free}
        client={client}
      />
    </div>
  );
};

const PurchaseButton = ({
  hasSubscription,
  free,
  onSuccessFree,
  subscription,
  client,
  totalAvailable,
}: {
  hasSubscription: boolean;
  free: boolean;
  subscription: Maybe<Subscription>;
  totalAvailable: number | null;
  onSuccessFree: () => void;
  client: Maybe<BaseClientFields>;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const [freeSubscription] = useMutation<Pick<Mutation, "freeSubscription">>(
    api.subscriptions.free
  );
  const [deleteSubscription] = useMutation<Pick<Mutation, "freeSubscription">>(
    api.subscriptions.delete
  );

  const toast = useMyToast();
  const isFreeSubscription =
    config.freeValidDate && subscription && subscription.isFreeSubscription;
  const theme = useTheme();

  const _unlockFreeSubscription = async () => {
    if (!clientId) return;

    onSuccessFree(); // just open the modal
    // const variables: MutationFreeSubscriptionArgs = {
    //   notes: "",
    //   clientId,
    // };

    // try {
    //   await freeSubscription({
    //     variables,
    //     refetchQueries: [api.subscriptions.active],
    //   });

    //   onSuccessFree();
    // } catch (err) {
    //   toast.show({
    //     message: (err as Error).message || "An error occurred.",
    //     status: "error",
    //   });
    // }
  };

  const _upgradePlan = async () => {
    setDrawerOpen(false);

    if (subscription && totalAvailable && totalAvailable === 25) {
      await deleteSubscription({
        variables: {
          subscriptionId: subscription.id,
        },
        refetchQueries: [api.subscriptions.active, api.clients.retrieve],
      });

      _showModal("CheckoutModal", { canCheckout: true });

      return;
    }

    // if a free 365 day subscription and didn't pay, just delete it so they can upgrade
    if (
      subscription &&
      totalAvailable &&
      totalAvailable === 365 &&
      subscription.totalCents === 0
    ) {
      await deleteSubscription({
        variables: {
          subscriptionId: subscription.id,
        },
        refetchQueries: [api.subscriptions.active, api.clients.retrieve],
      });

      _showModal("CheckoutModal", { canCheckout: true });

      return;
    }

    window.open(
      "mailto:team@awaken.tax?subject=I would like to upgrade my tax plan to a higher tier."
    );
    // if ((window as any).Intercom) {
    //   (window as any).Intercom(
    //     "showNewMessage",
    //     "Hello, I would like to upgrade my tax plan to a higher tier. "
    //   );
    // }
  };

  const _copyLink = () => {
    if (!client) return;

    dispatch(show("ShareModal"));

    // const referralCode = client?.referralCode || "";

    // navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    // toast.show({
    //   message: "Copied sharable link to clipboard!",
    //   status: "info",
    // });
  };

  const _buyTransactions = () => {
    setDrawerOpen(false);

    _showModal("CheckoutModal", { canCheckout: true });
  };

  const hasFreeSubscription =
    hasSubscription && subscription?.isFreeSubscription;

  // only show this if we are still allowing free subscriptions
  // and they have one
  if (free && hasFreeSubscription) {
    return (
      <>
        <SmallButton
          label={"Share with friends"}
          icon={
            <motion.i
              animate={{ scale: 1.1 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.5,
              }}
              style={{
                top: 0,
                position: "relative",
                fontSize: 16,
                color: colors.primary,
              }}
              className="fa-sharp fa-share"
            />
          }
          onClick={_copyLink}
          style={{
            marginTop: "1rem",
            color: colors.primary,
          }}
          bg={colors.gray80}
          border={`1px solid ${colors.gray70}`}
          _hover={{ bg: colors.gray70 }}
          labelStyle={{
            textAlign: "center",
            width: "100%",
            color: colors.black,
          }}
        />
        <AwakenTooltip
          margin="0.5rem"
          message="You will be able to download tax reports for FREE before March 1st. After that, you'll need to upgrade your subscription. Your free subscription includes unlimited wallets/exchanges and unlimited transactions!"
        >
          <HStack
            alignItems="center"
            marginTop="0.75rem"
            justifyContent="center"
          >
            <Text
              textDecor="underline"
              whiteSpace="normal"
              fontSize="xs"
              textAlign="center"
            >
              {/* party font awesome icon:  */}
              Free until{" "}
              {moment(config.freeValidDate).utc().format("MMMM Do, YYYY")}{" "}
            </Text>
            <i
              style={{
                color: colors.pink50,
                fontSize: 12,
              }}
              className="fa-sharp fa-glass-cheers"
            />
          </HStack>
        </AwakenTooltip>
      </>
    );
  }

  // otherwise if they have a non free subscription, show upgrade button
  if (hasSubscription && !subscription?.isFreeSubscription) {
    return (
      <>
        <SmallButton
          iconName={"fa-sharp fa-credit-card"}
          label={"Upgrade Tax Plan"}
          onClick={_upgradePlan}
          style={{
            marginTop: "1rem",
            color: undefined,
          }}
          iconStyle={{
            color: theme.text,
          }}
          bg={theme.secondaryBackground}
          border={`1px solid ${theme.border}`}
          _hover={{ bg: theme.secondaryBackground }}
          labelStyle={{
            textAlign: "center",
            width: "100%",
            color: theme.text,
          }}
        />
      </>
    );
  }

  if (free) {
    return (
      <SmallButton
        icon={
          // animate this to pulse with motion.div
          <motion.i
            animate={{ scale: 1.1 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            }}
            style={{
              top: 0,
              position: "relative",
              fontSize: 16,
              color: colors.white,
            }}
            className="fa-sharp fa-unlock"
          />
        }
        label={"Unlock free reports"}
        onClick={_unlockFreeSubscription}
        style={{
          marginTop: "1rem",
          color: colors.white,
        }}
        bg={colors.primary}
        border={undefined}
        _hover={{ bg: undefined }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.white,
        }}
      />
    );
  }

  return (
    <>
      <SmallButton
        iconName={"fa-sharp fa-unlock"}
        label={"Unlock Tax Plan"}
        onClick={_buyTransactions}
        style={{
          marginTop: "1rem",
          color: colors.white,
        }}
        bg={colors.primary}
        border={undefined}
        _hover={{ bg: undefined }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.white,
        }}
      />

      {/* show an expired message if they have a free subscription */}
      {hasFreeSubscription && (
        <HStack alignItems="center" marginTop="0.5rem" justifyContent="center">
          <Text whiteSpace="normal" fontSize="xs" textAlign="center">
            {/* party font awesome icon:  */}
            Your free subscription expired.
          </Text>
        </HStack>
      )}

      <HStack
        cursor="pointer"
        onClick={() => {
          dispatch(
            show("ReferralCodeModal", {
              clientId: clientId,
            })
          );
        }}
        alignItems="center"
        marginTop="0.5rem"
        justifyContent="center"
      >
        <Text
          color={theme.text}
          whiteSpace="normal"
          fontSize="sm"
          textAlign="center"
        >
          {/* party font awesome icon:  */}
          Enter a referral code <i className="fa-sharp fa-key" />
        </Text>
      </HStack>
    </>
  );
};

const UnlimitedCX = ({
  subscription,
}: {
  subscription: Maybe<Subscription>;
}) => {
  const dispatch = useDispatch();
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);

  const _upgradeModal = () => {
    setDrawerOpen(false);
    dispatch(show("UpgradeInfoModal"));
  };

  // you need a subscription to upgrade
  if (!subscription) {
    return null;
  }

  return (
    <div style={{ display: "flex" }}>
      <SmallButton
        // help icon

        icon={
          // animate this to pulse with motion.div
          <motion.i
            animate={{ scale: 1.2 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            }}
            style={{
              top: 0,
              position: "relative",
              fontSize: 16,
              color: colors.primary,
            }}
            className="fa-sharp fa-square-up"
          />
        }
        label={"Upgrade Support"}
        onClick={_upgradeModal}
        style={{
          color: colors.black,
          marginBottom: 0,
          border: `1px solid ${colors.lightBlue80}`,
        }}
        bg={colors.white}
        border={undefined}
        _hover={{ bg: undefined }}
        iconStyle={{
          color: colors.primary,
        }}
        labelStyle={{
          textAlign: "center",
          width: "100%",
          color: colors.black,
        }}
      />
    </div>
  );
};

const ContactUs = () => {
  return (
    <a
      href="mailto:team@awaken.tax"
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{
        color: colors.black,
        width: "100%",
      }}
    >
      <SmallButton iconName="fa-sharp fa-envelope" label="Contact Us" />
    </a>
  );
};

const SmallButton = (props: TouchableProps) => {
  return (
    <Touchable
      style={{
        position: "relative",
        cursor: "pointer",
        display: "inline-block",
        width: "100%",
        padding: "0.5rem 1rem",
        ...props.style,
      }}
      iconName={props.iconName}
      iconPosition="left"
      iconStyle={{
        color: props.style?.color,
      }}
      bg={colors.gray90}
      fontSize="sm"
      _hover={{ bg: colors.gray80 }}
      onClick={props.onClick}
      // labelStyle={{ fontSize: 14 }}
      // iconName="fa-sharp fa-plus"
      // iconPosition="left"
      label={props.label}
      {...omit(props, ["style"])}
    />
  );
};

const SocialButtons = () => {
  return (
    <HStack
      margin="0 !important"
      w="100%"
      alignItems="center"
      justifyContent="center"
      padding="0"
      // paddingBottom="1rem"
      bg={colors.white}
    >
      {/*<Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href={config.discordLink}
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fab fa-discord"
          ></i>
        </ChakraLink>
          </Box>*/}

      <Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href={config.twitterLink}
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fab fa-twitter"
          ></i>
        </ChakraLink>
      </Box>

      <Box>
        <ChakraLink
          display="block"
          rel="noreferrer"
          target={isMobile ? undefined : "_blank"}
          href="mailto:team@awaken.tax"
          style={{
            margin: "0.5rem",
          }}
        >
          <i
            style={{
              color: colors.gray30,
              fontSize: 15,
            }}
            className="fa-sharp fa-envelope"
          ></i>
        </ChakraLink>
      </Box>
    </HStack>
  );
};

const VIPSection = ({ client }: { client: Maybe<BaseClientFields> }) => {
  const dispatch = useDispatch();

  const { data } = useQuery<Pick<Query, "getClientBookkeepingQuotes">>(
    api.bookkeepingQuotes.listForClient,
    {
      variables: {
        clientId: client?.id || "",
      },
    }
  );

  const quotes = data?.getClientBookkeepingQuotes ?? [];

  const newestQuote = useMemo(() => {
    return maxBy(
      quotes.filter((q) => !!q.requestYear),
      (q) => q.requestYear
    );
  }, [quotes]);

  const hasVIP = useMemo(
    () => !!newestQuote && newestQuote?.hasPaid,
    [newestQuote]
  );

  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  const _openModal = () => {
    dispatch(show("VIPQuoteRequestModal"));
  };

  if (hasVIP) {
    return (
      <div
        style={{
          width: "100%",
          marginBottom: 0,
          cursor: "pointer",
          margin: isLarge ? "0 auto" : "0 auto",
        }}
      >
        <div
          style={{
            // borderRadius: 10,
            // gold background gradient
            background: `linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)`,
            // border: `1px solid ${colors.primary}`,
            padding: 12,
            display: "flex",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <div style={{ whiteSpace: "nowrap", width: "100%" }}>
            <HStack
              style={{
                marginBottom: 5,
                alignItems: "center",
              }}
            >
              <Text
                color={colors.gray20}
                fontWeight="bold"
                fontSize="md"
                style={{
                  flex: 1,
                  fontStretch: "extra-expanded",
                }}
              >
                Subscribed to VIP
              </Text>
              <i style={{ fontSize: 18 }} className="fas fa-circle-check" />
            </HStack>
            <Text
              fontSize="sm"
              color={colors.gray20}
              style={{ marginBottom: 5 }}
              // make sure this wraps next line
              whiteSpace="normal"
            >
              You're subscribed to VIP service.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  if (newestQuote) {
    // link to the quotes page
    return (
      <Link style={{ width: "100%" }} to={`/clients/${client?.id}/quotes`}>
        <div
          style={{
            width: "100%",
            marginBottom: 0,
            cursor: "pointer",
            margin: isLarge ? "0 auto" : "0 auto",
          }}
        >
          <div
            style={{
              // borderRadius: 10,
              // gold background gradient
              background:
                theme.theme === "light"
                  ? `linear-gradient(45deg, ${colors.green90} 0%, ${colors.green90} 50%, ${colors.lightBlue90} 100%)`
                  : `linear-gradient(45deg, ${colors.green05} 0%, ${colors.green05} 50%, ${colors.lightBlue10} 100%)`,

              // border: `1px solid ${colors.primary}`,
              padding: 12,
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div style={{ whiteSpace: "nowrap", width: "100%" }}>
              <HStack style={{ marginBottom: 5, alignItems: "center" }}>
                <Text
                  color={theme.theme === "light" ? colors.gray20 : colors.white}
                  fontWeight="bold"
                  fontSize="md"
                  style={{
                    flex: 1,
                    fontStretch: "extra-expanded",
                  }}
                >
                  VIP In-progress <i style={{ marginLeft: 10 }} />
                </Text>
                <i
                  style={{
                    fontSize: 18,
                    color:
                      theme.theme === "light" ? colors.gray20 : colors.white,
                  }}
                  className="fas fa-hourglass-half"
                />
              </HStack>
              <Text
                fontSize="sm"
                color={theme.theme === "light" ? colors.gray20 : colors.white}
                style={{ marginBottom: 5 }}
                // make sure this wraps next line
                whiteSpace="normal"
              >
                We'll get back to you shortly.
              </Text>

              <Text
                style={{
                  fontWeight: "bold",
                  color: theme.theme === "light" ? colors.gray20 : colors.white,
                }}
                fontSize="sm"
              >
                See details <i className="fa-sharp fa-arrow-right" />
              </Text>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 0,
        cursor: "pointer",
        margin: isLarge ? "0 auto" : "0 auto",
      }}
      onClick={_openModal}
    >
      <div
        style={{
          // borderRadius: 10,
          // gold background gradient
          background:
            theme.theme === "light"
              ? `linear-gradient(45deg, ${colors.lightBlue80} 0%, ${colors.lightBlue80} 50%, ${colors.yellow90} 100%)`
              : `linear-gradient(45deg, ${colors.lightBlue10} 0%, ${colors.lightBlue10} 50%, ${colors.green10} 100%)`,
          // border: `1px solid ${colors.primary}`,
          padding: 12,
          display: "flex",
          flexDirection: "row",
          position: "relative",
        }}
      >
        <div style={{ whiteSpace: "nowrap", width: "100%" }}>
          <HStack
            style={{
              marginBottom: 5,
            }}
          >
            <Text
              color={theme.theme === "light" ? colors.gray20 : colors.white}
              fontWeight="bold"
              fontSize="md"
              style={{
                flex: 1,
                fontStretch: "extra-expanded",
              }}
            >
              VIP Service
            </Text>
            <i
              style={{
                fontSize: 18,
                color: theme.theme === "light" ? colors.gray20 : colors.white,
              }}
              className="fas fa-whale"
            />
          </HStack>
          <Text
            fontSize="sm"
            color={theme.theme === "light" ? colors.gray20 : colors.white}
            style={{ marginBottom: 5 }}
            // make sure this wraps next line
            whiteSpace="normal"
          >
            Year-round bookkeeping. Starting at $100/mo.
          </Text>

          <Text
            style={{
              fontWeight: "bold",
              color: theme.theme === "light" ? colors.gray20 : colors.white,
            }}
            fontSize="sm"
          >
            Apply now <i className="fa-sharp fa-arrow-right" />
          </Text>
        </div>
      </div>
    </div>
  );
};
