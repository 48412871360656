import React, { useCallback } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
  Box,
  Center,
  Text,
  Image,
  HStack,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AuthenticationService,
  AuthenticationType,
} from "src/modules/authentication";
import { BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { useMe, useMyToast } from "src/hooks";
import { MutationCreateUserArgs } from "src/api/generated/types";
import { getRedirectPath } from "../utils";
import { GoogleButton } from "../components/Google";
import { noop } from "lodash/fp";
import { getMetadataInfo, isWhiteLabeledDomain } from "src/utils/whitelabel";
import { MultiFactorError, getMultiFactorResolver } from "firebase/auth";
import { auth } from "src/utils/firebase";
import { FirebaseError } from "firebase/app";
import { useDispatch } from "react-redux";
import { InjectedProps, show } from "redux-modal";
import { AppleButton } from "../components/Apple";
import { useTheme } from "src/hooks/useTheme";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";
import { sleep } from "radash";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required.").nullable(),
  password: yup.string().min(6).required("Password is required.").nullable(),
});

type FormValues = {
  email: string;
  password: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
  password: "",
};

export function Login() {
  const toast = useMyToast();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
    fetchPolicy: "no-cache",
  });
  const [createUser] = useMutation<{ createUser?: BaseUserFields }>(
    api.users.create
  );
  const isWhiteLabel = isWhiteLabeledDomain();
  const dispatch = useDispatch();
  const theme = useTheme();

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const _navigateForUser = (user?: BaseUserFields) => {
    const path = getRedirectPath(user);
    const redirect = search.get("redirect") || null;

    console.log("[navigating to " + (redirect || path) + "]");

    return navigate(redirect || path);
  };

  const _onSuccess2FA = async () => {
    await sleep(100);

    const me = await getMe({
      fetchPolicy: "no-cache",
    });

    await _navigateForUser(me.data?.me);
  };

  const _parseFirebaseError = (message: string, error: any) => {
    if (error instanceof FirebaseError) {
      const { code } = error;

      if (code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(
          auth,
          error as MultiFactorError
        );

        const hasTotp = resolver.hints.find((i) => i.factorId === "totp");

        if (hasTotp) {
          dispatch(
            show("TOTPModal", {
              flowType: "sign_in",
              multiFactorError: error,
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
              onSuccess: _onSuccess2FA,
            })
          );
          return;
        }

        dispatch(
          show("PhoneVerificationModal", {
            flowType: "sign_in",
            multiFactorError: error,
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
            onSuccess: _onSuccess2FA,
          })
        );
        return;
      }
    }

    toast.show({ message, status: "error" });
  };

  const _logAuthError = ({
    message,
    error,
  }: {
    message: string;
    error: any;
  }) => {
    _parseFirebaseError(message, error);
  };

  const onSubmit = useCallback(async (values: FormValues) => {
    const authResponse = await AuthenticationService.login({
      type: AuthenticationType.EmailAndPassword,
      email: values.email.trim().toLowerCase(),
      password: values.password,
    });

    if (authResponse.isFailure()) {
      console.log(authResponse);
      _parseFirebaseError(authResponse.error.message, authResponse.error);
      return;
    }

    try {
      const firebaseUser = authResponse.value;

      const res = await getMe();

      // if there is no user for them but they are logged in create their user
      // for them. this shouldn't really be called ever because createUser now creates firebase user on the server,
      // but just in case we have this fallback to create a user (server will error if they already exist so it's all good)
      if (!res.data?.me) {
        const referredDomainUrl = window.location.hostname;

        const params: MutationCreateUserArgs = {
          email: firebaseUser.user.email!,
          name: firebaseUser.user.displayName || "",
          phoneNumber: firebaseUser.user.phoneNumber,
          referredDomainUrl,
        };

        const newUser = await createUser({
          variables: params,
        });

        return _navigateForUser(newUser.data?.createUser);
      }

      return _navigateForUser(res.data?.me);
    } catch (err) {
      return toast.show({
        status: "error",
        message: (err as Error)?.message || "An unknown error occurred.",
      });
    }
  }, []);

  const metadata = getMetadataInfo();

  return (
    <NoNavBarPageTemplate>
      <HStack justifyContent="center" height="100%" minHeight="100vh">
        <Center
          display="flex"
          flexDir="column"
          maxWidth="30rem"
          margin="auto"
          w="100%"
          padding="2rem"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            {!isWhiteLabel && (
              <Link to="/">
                <Center>
                  <Image
                    src={theme.theme === "light" ? Logo : WhiteLogo}
                    cursor="pointer"
                    w="10rem"
                    paddingTop="1rem"
                    paddingBottom="2rem"
                  />
                </Center>
              </Link>
            )}
            {metadata?.logo && (
              <Link to="/">
                <Center>
                  <Image
                    src={metadata.logo}
                    cursor="pointer"
                    w="15rem"
                    paddingTop="1rem"
                    paddingBottom="2rem"
                  />
                </Center>
              </Link>
            )}
            <Box width="100%">
              <Input label="Email" isRequired control={control} name="email" />
              <Input
                label="Password"
                isRequired
                control={control}
                type="password"
                name="password"
              />
            </Box>
            <br />
            <Button
              isLoading={isSubmitting}
              width="100%"
              variant="primary"
              type="submit"
              padding="1rem 2rem"
            >
              Log in
            </Button>
            <br /> <br />
            <GoogleButton
              label="Sign in"
              referralCode={null}
              onSuccess={_navigateForUser}
              onError={_logAuthError}
            />
            <br />
            <br />
            <AppleButton
              label="Sign in"
              referralCode={null}
              onSuccess={_navigateForUser}
              onError={_logAuthError}
            />
            <Divider margin="2rem 0" />
            <Text color={theme.text}>
              Need an account?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/signup">
                  <Text color={colors.primary}>Sign up</Text>
                </Link>
              </Box>
            </Text>
            <Text color={theme.text} marginTop="0.5rem">
              Forgot your password?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/forgot-password">
                  <Text color={colors.primary}>Click here to reset</Text>
                </Link>
              </Box>
            </Text>
          </form>
        </Center>
      </HStack>
    </NoNavBarPageTemplate>
  );
}
